import Vuex from 'vuex';
import Vue from 'vue';
import createStoreModules from '@/store/store-modules';
import subscribeModule from '@/store/helpers/pub-sub-module';
import populateGlobalStore from '@/store/helpers/populate-global-store';
import setStorePrefixes from '@/store/helpers/set-store-prefixes';
import '@/store/helpers/api-store-helper';
import '@/store/helpers/pt-store-computed';

Vue.use(Vuex);

/**
 * Creates a new store that is ready to go
 *
 * @param {string} apiPrefix - The api prefix for all requests ('/lineage', '/services')
 * @param {object} headers - The map of custom headers to add to all requests
 * @param {Object} includeGlobals - Whether to populate the global and user state caches
 * @return {Vuex.Store} The created store
 */
export default function (apiPrefix, headers, includeGlobals) {
	setStorePrefixes(apiPrefix, headers);

	const modules = createStoreModules();

	const store = new Vuex.Store({
		modules
	});

	if (includeGlobals) {
		if (window.SUBSCRIPTION_INTERVAL) {
			subscribeModule(store, 'currentReadings', { modifiedSince: Date.now() });
		}
		populateGlobalStore(store, 'actorClasses');
		populateGlobalStore(store, 'assetModels');
		if (window.CURRENT_USER && window.CURRENT_USER.dbId) {
			populateGlobalStore(store, 'currentUserPreferences');
			populateGlobalStore(store, 'appState');
		}
	}

	window.PT_STORE = store;
	let event;
	if (typeof Event === 'function') {
		event = new Event('storeLoaded');
	} else {
		// IE doesn't like new Event...
		event = document.createEvent('Event');
		event.initEvent('storeLoaded', true, true);
	}
	document.dispatchEvent(event);
	return store;
}
