import createAxiosInstance from '@/store/helpers/create-axios-instance';
import { moduleClean, cleanCacheIterable } from '@/store/helpers/store-clean';
import { get } from '@/store/request';
import { getInScope } from '@/utils/object';
import generateRequestId from '@/store/helpers/generateRequestId';
import CancelRequest from '@/store/helpers/CancelRequest';
import { chunkify, replace } from '@/utils/arrays';

/** {string} The get action/getter endpoint */
const GET = '/get';

/** {string} The addToCache mutation endpoint */
const ADD_TO_CACHE = '/addToCache';

/** {object} The mapping of unique requests. Used with the trackRequest function */
const currentRequests = {};

/** {Object[]} The list of current api actions */
const currentApiActions = [];

/**
 * Keeps track of a unique request and automatically removes it when the request is completed
 * regardless of its pass/fail state
 *
 * @param {string} id - The url + request parameters combined as a string to uniquely identify
 * 		the request
 * @param {Promise} request - The sent axios request
 * @return {Promise} The cached request promise
 */
function trackRequest(id, request) {
	currentRequests[id] = request;
	request.finally(() => {
		delete currentRequests[id];
	});
	return currentRequests[id];
}

/**
 * Tracks an api action (i.e. post, put, delete) to prevent duplicate requests from happening
 *
 * @param {axios} axiosInstance - The configured axios instance
 * @param {string} type - The request type (e.g. post, put, delete)
 * @param {...*} args - The arguments to pass to the request function
 * @return {axios} The original axios request
 */
function trackApiAction(axiosInstance, type, ...args) {
	const existing = _.find(
		currentApiActions,
		(action) => action.type === type
			&& action.axiosInstance === axiosInstance
			&& _.isEqual(action.args, args)
	);

	if (existing) {
		return existing.request;
	}

	const action = {
		axiosInstance,
		type,
		args,
		request: axiosInstance[type](...args).finally(() => {
			setTimeout(() => {
				currentApiActions.splice(currentApiActions.indexOf(action), 1);
			}, 100);
		})
	};

	currentApiActions.push(action);

	return action.request;
}

/**
 * Given a linkKey, checks the cache to see if data is populated under that linked key
 *
 * @param {object} currentContext - The context of the current api store module
 * @param {string} linkKey - The key to lookup in the cache
 * @param {string|function} mutation - The mutation to used to lookup the data in the
	store. See module definition linkMap for examples.
 * @param {object} data - The existing cached data (to pass to the mutation function)
 * @return {Boolean} True when data exists in the cache under the given linkKey, false otherwise
 */
function hasLinkedDataInCache(currentContext, linkKey, mutation, data) {
	if (linkKey && mutation) {
		return typeof mutation === 'string'
			? currentContext.rootGetters[mutation + GET]({ linkedKey: linkKey })
			: data && currentContext.rootGetters[mutation(data) + GET]({ linkedKey: linkKey });
	}
	return false;
}

/**
 * Generates the standard getters for getting items from the cache
 * <ul>This provides the following getters
 *	<li>get - All interactions with the cache use get
 *	<li>getAxios - Specific to allow outside instances to use the created axios instance
 * </ul>
 *
 * @param {axios} axiosInstance - The axios instance that the module is using
 * @return {object} The generated getters
 */
function generateModuleGetters(axiosInstance) {
	return {
		/**
		 * Gets the specific item from the cache.
		 *
		 * @param {object} currentState - The current state of the module
		 * @return {function} The cache accessor function
		 * <ul>parameters
		 *	<li>{string|number|object} params - The parameters of retrieval for a cached item.
		 *		<ul>If passing an object you have the following parameters
		 *			<li>{string|number} key - The cached item's specific key
		 *			<li>{string} linkedKey - The key of a linked item
		 *			<li>{boolean} private - the private instance of the cached item
		 *		</ul>
		 * </ul>
		 */
		get(currentState) {
			return function (params) {
				const isParamsObject = typeof params === 'object';
				let item;
				if (params === undefined) {
					return undefined;
				}
				if (!isParamsObject) {
					item = currentState.cache[params];
				} else if (params.linkedKey) {
					item = currentState.cache[params.linkedKey];
				} else if (params.key) {
					item = currentState.cache[params.key];
				}

				if (item && isParamsObject && params.private) {
					return Vue.observable($.extend(true, _.isArray(item) ? [] : {}, item));
				}
				return item;
			};
		},

		/**
		 * Gets the axios instance that the module is using.
		 * Note: This is called without parenthesis' since it doesn't need any arguments.
		 * Use only when having to access unsupported endpoints that are still within the
		 * modules domain (e.g. linage/api/alarms/123/acknowledge
		 *
		 * @return {axios} The axios instance
		 */
		getAxios() {
			return axiosInstance;
		},

		/**
		 * Generates a new cancel request token for whatever called it
		 *
		 * @param {object} currentState - The current state of the module
		 * @return {function(*=): *} The function which returns a new cancel request token
		 */
		getCancelToken(currentState) {
			return function (params) {
				const id = generateRequestId(axiosInstance, params);
				const cancelRequest = currentState.cancelTokens[id];

				return cancelRequest ? cancelRequest.requestToken() : undefined;
			};
		},

		/**
		 * @param {object} currentState - The current state of the module
		 * @return {*} - The last modified key
		 */
		lastModifiedKey(currentState) {
			return currentState.lastModifiedKey;
		}
	};
}

/**
 * General method for adding a single item to the current cache.
 * <ul>This will:
 *	<li>Add the specified data to the cache, if not undefined and not equal to the existing
 * data
 *	<li>Checks the module map for set attributes and calls commit mutation with the attribute
 * </ul>
 *
 * @param {object} storeContext - The base store context
 * @param {object} currentState - The current state of the module
 * @param {string} dataKey - The unique identified for items in the cache
 * @param {object} data - The data to add to the cache
 * @param {object<string, string>} moduleMap - Map of attributes to external module mutations
 * @return {object} The corresponding cached item
 */
function addItemToCache(storeContext, currentState, dataKey, data, moduleMap) {
	const key = data && dataKey !== undefined ? getInScope(data, dataKey) : undefined;
	if (key !== undefined && !_.isEqual(currentState.cache[key], data)) {
		Vue.set(currentState.cache, key, data);
	}
	if (data && moduleMap) {
		_.each(moduleMap, (mutation, attributeKey) => {
			let computedMutation;
			if (data[attributeKey] !== undefined) {
				computedMutation = typeof mutation === 'string'
					? mutation
					: mutation(data[attributeKey]);
				storeContext.commit(computedMutation + ADD_TO_CACHE, {
					data: data[attributeKey],
					returnStoredData(storedData) {
						data[attributeKey] = storedData;
					}
				});
			}
		});
	}
	return key ? currentState.cache[key] : data;
}

/**
 * Generates the standard mutations for interacting with the cache
 * <ul>This provides the following mutations:
 *	<li>addToCache
 *	<li>removeFromCache
 *	<li>deepCleanCache
 * </ul>
 *
 * @param {string} dataKey - The unique identifier for items in the cache
 * @param {object<string, string>} moduleMap - Map of attributes to external module mutations
 * @param {boolean} shouldClean - Whether or not to allow calls to deepCleanCache
 * @return {object} The standard module mutations
 */
// eslint-disable-next-line max-lines-per-function
function generateModuleMutations(dataKey, moduleMap, shouldClean) {
	return {
		/**
		 * Adds multiple items to the cache.
		 * If the payload contains a linkedKey it will add the link
		 *
		 * @param {object} currentState - The current state of the module
		 * @param {object} payload - The mutation payload
		 * <ul>Consists of:
		 *	<li>{object|array<object>} data - The item or list of items to add to the cache
		 *	<li>{string} linkedKey - The key for fetching a linked item from teh cache
		 *  <li>{boolean} isMap - The payload's data is a map of items
		 *  <li>{boolean} arrayMapPrefix - The payload's data is a map of lists of items but we
		 *  		need to track each item separately
		 *  <li>{Function} returnStoredData - Called with the value that was stored in the cache
		 *  <li>{Boolean} fromAction - If the mutation was called from an action
		 * </ul>
		 */
		addToCache(currentState, payload) {
			const storeContext = this;
			let storedData;
			let assumedKey;
			if (payload.arrayMapPrefix) {
				storedData = _.mapObject(payload.data, (dataArray, key) => {
					const items = _.map(
						dataArray,
						(data) => addItemToCache(storeContext, currentState, dataKey, data, moduleMap)
					);
					Vue.set(currentState.cache, payload.arrayMapPrefix + key, items);
					return items;
				});
			} else if (_.isArray(payload.data) || payload.isMap) {
				storedData = (payload.isMap ? _.mapObject : _.map)(
					payload.data,
					(data) => (_.isArray(data)
						? _.map(
							data,
							(item) => addItemToCache(storeContext, currentState, dataKey, item, moduleMap)
						)
						: addItemToCache(storeContext, currentState, dataKey, data, moduleMap))
				);
			} else {
				storedData = addItemToCache(
					storeContext,
					currentState,
					dataKey,
					payload.data,
					moduleMap
				);
				assumedKey = storedData ? getInScope(storedData, dataKey) : undefined;
			}

			if (payload.linkedKey && payload.linkedKey !== assumedKey) {
				const existingPayload = currentState.cache[payload.linkedKey];
				if (existingPayload !== undefined && _.isEqual(existingPayload, storedData)) {
					// Do nothing if they are the same
				} else if (existingPayload !== undefined
					&& _.isArray(existingPayload)
					&& _.isArray(storedData)
				) {
					replace(existingPayload, storedData);
				} else if (existingPayload !== undefined
					&& _.isObject(existingPayload) && !_.isArray(existingPayload)
					&& _.isObject(storedData) && !_.isArray(storedData)
				) {
					const keysToRemove = _.difference(Object.keys(existingPayload), Object.keys(storedData));
					Object.assign(existingPayload, storedData);
					_.each(keysToRemove, (removedKey) => {
						Vue.delete(existingPayload, removedKey);
					});
				} else {
					Vue.set(currentState.cache, payload.linkedKey, storedData);
				}
			}

			if (payload.returnStoredData) {
				payload.returnStoredData(storedData);
			}

			// Ensures linked module items are not removed when the original is still being used
			if (payload.fromAction && (payload.linkedKey || (assumedKey !== undefined && typeof assumedKey !== 'object'))) {
				const apiWatcherKey = payload.linkedKey || assumedKey;
				if (currentState.apiWatchers[apiWatcherKey]) {
					currentState.apiWatchers[apiWatcherKey]();
				}
				currentState.apiWatchers[apiWatcherKey] = storeContext.watch(
					() => storedData,
					() => {},
					{ deep: true }
				);
			}
		},

		/**
		 * Removes an item from the cache.
		 * If removing a linked key this will not actually remove any of the items that the
		 * key contained from the cache
		 *
		 * @param {object} currentState - The current state of the module
		 * @param {string|object} payload - The cache key to remove
		 * <ul>If string removes the single item from the cache. Otherwise consists of:
		 *	<li>{string} key - The key to remove
		 *	<li>{string} linkedKey - The link to remove
		 * </ul>
		 */
		removeFromCache(currentState, payload) {
			if (typeof payload !== 'object') {
				Vue.delete(currentState.cache, `${payload}`);
			} else if (payload.key !== undefined) {
				Vue.delete(currentState.cache, payload.key);
			} else if (payload.linkedKey !== undefined) {
				Vue.delete(currentState.cache, payload.linkedKey);
			}
		},

		/**
		 * Removes all currently unused items from the cache.
		 * This should only be called by using $store.dispatch('clean') or in a unit test
		 * <ul>This will:
		 *	<li>Remove any unused cached links
		 *	<li>Remove any unused cached items that aren't in a linked cache
		 * </ul>
		 */
		deepCleanCache(currentState) {
			if (shouldClean) {
				_.each(currentState.cache, cleanCacheIterable.bind(undefined, currentState.apiWatchers));
			}
		},

		/**
		 * Adds a cancel request to the cache
		 *
		 * @param {object} currentState - The current state of the module
		 * @param {object} payload - The action's payload
		 */
		addCancelRequest(currentState, payload) {
			currentState.cancelTokens[payload.id] = payload.cancelRequest;
		},

		/**
		 * Removes a store cancel request from the cache
		 *
		 * @param {object} currentState - The current state of the module
		 * @param {object} payload - The action's payload
		 * @param {Number|String} payload.id - The specific request to remove
		 */
		removeCancelRequest(currentState, { id }) {
			delete currentState.cancelTokens[id];
		}
	};
}

/**
 * Fetches the necessary (missing) links on the original data
 *
 * @param {object} currentContext - The context of the current api store module
 * @param {object} originalData - The original response data
 * @param {object<string, string|function>} linkMap -
 *		Map of attribute links to external module mutations
 * @param {boolean} ignoreCache - when true, ignores the cache and fetches from the API
 * @return {Promise} The promise that will be resolved when all links are available
 */
function fetchNecessaryApiLinks(currentContext, originalData, linkMap, ignoreCache) {
	return Promise.all(_.map(linkMap, (mutation, attributeKey) => {
		const linkKey = originalData[attributeKey];
		let id;
		/* get linked data from API for either of these scenarios:
				1) specifically told to ignore the cache and there is
					a relationship to other data defined by a linkKey
				2) no linked data exists in the cache
			*/
		if (linkKey !== undefined
				&& (ignoreCache || !hasLinkedDataInCache(currentContext, linkKey, mutation))
		) {
			id = linkKey;
			return currentRequests[id] || trackRequest(id, get(id)).then((linkResponse) => {
				const computedMutation = typeof mutation === 'string'
					? mutation
					: mutation(originalData);

				// Commit the mutation as root so we have access to other modules
				currentContext.commit(computedMutation + ADD_TO_CACHE, {
					data: linkResponse.data,
					linkedKey: linkKey
				}, {
					root: true
				});
			});
		}
		// there is no linked data
		return undefined;
	}));
}

/**
 * Resolves the api url with the specified options
 *
 * @param {string} baseUrl - The base URL potentially being modified
 * @param {object} options - The url modification options
 * <ul>Consists of:
 *  <li>{string} replaceUrl - Replaces the url (/api/{replaceUrl})
 *  <li>{boolean} useMy - Use the current user specific endpoints (/api/my/...)
 *  <li>{number|string} customerId - Use the customer specified (/api/customers/{customerId}/...)
 *  <li>{string} injectedUrl - Use a custom injected url (/api/{injectedUrl}/{baseUrl})
 * </ul>
 * @return {string} The resolved url that can be used
 */
function resolveUrl(baseUrl, options) {
	if (options.replaceUrl) {
		baseUrl = baseUrl.replace(/\/api\/.*$/, options.replaceUrl.startsWith('/api/') ? options.replaceUrl : `/api/${options.replaceUrl}`);
	}

	if (options.injectedUrl) {
		baseUrl = baseUrl.replace(/\/api\//, options.injectedUrl.startsWith('/api/') ? options.injectedUrl : `/api/${options.injectedUrl}/`);
	}

	if (options.useMy) {
		baseUrl = baseUrl.replace(/\/api\//, '/api/my/');
	}

	if (options.customerId) {
		baseUrl = baseUrl.replace(/\/api\//, `/api/customers/${options.customerId}/`);
	}

	return baseUrl;
}

/**
 * @return {Promise<unknown>} The promise that will resolve after 25ms
 */
function delayRequest() {
	return new Promise((resolve) => {
		setTimeout(resolve, 25);
	});
}

/**
 * Sends a generic request to the api
 *
 * @param {Object} request - The request options
 * @param {axios} axiosInstance - The configured axios instance for the module
 * @return {Promise} The state of the request
 */
function createGenericRequest(request, axiosInstance) {
	const requestOptions = {
		params: request.params,
		baseURL: resolveUrl(axiosInstance.defaults.baseURL, request)
	};
	return trackApiAction(
		axiosInstance,
		request.type || 'post',
		request.requestUrl,
		request.type === 'delete' ? requestOptions : request.data,
		requestOptions
	).then(request.onSuccess, request.onError);
}

/**
 * Chunks the list of requests into batches so the network doesn't run out of resources
 * Note: Disabling some of the arrow body styles since sonar doesn't like them nested 3 deep
 *
 * @param {Object[]} requests - The list of requests
 * @param {axios} axiosInstance - The configured axios instance for the module
 * @return {Promise<unknown>} The state of the requests
 */
function chunkRequests(requests, axiosInstance) {
	const chunks = chunkify(requests, 250);

	// eslint-disable-next-line arrow-body-style
	return _.reduce(chunks, (promise, chunk) => {
		// eslint-disable-next-line arrow-body-style
		return promise.then(() => {
			return Promise.all(
				_.map(chunk, (request) => createGenericRequest(request, axiosInstance))
			).then(delayRequest);
		});
	}, Promise.resolve());
}

/**
 * Generates the api action methods that are hooked up with the modules cache
 * <ul>This provides the following apis:
 *	<li>get - GET w/ key + request parameters
 *	<li>create - POST w/ data + request parameters
 *	<li>update - PUT w/ key + data + request parameters
 *	<li>remove - DELETE w/ key + request parameters
 * </ul>
 * Note: This also provides 'clean' which is added as a root action instead of a module action
 *
 * @param {axios} axiosInstance - The axios instance to use
 * @param {string} dataKey - The unique key look up
 * @param {object<string, string|function>} linkMap -
 *		Map of attribute links to external module mutations
 * @param {boolean} noClean - Whether or not to ignore calls to dispatch clean
 * @return {object} - The standard api interactions
 */
// eslint-disable-next-line max-lines-per-function
function generateModuleActions(axiosInstance, dataKey, linkMap, noClean) {
	return {
		/**
		 * Populates the cache with the desired api data if the key was not in the cache.
		 * Note: Specify ignore to always fetch from the cache
		 *
		 * @param {object} currentContext - The context of the current api store module
		 * @param {object} options - The get options
		 * <ul>Consists of:
		 *	<li>{string|number} key - The api key to use
		 *	<li>{string} linkedKey - The linked key to set the api response as
		 *	<li>{object} params - The request parameters for the fetch
		 *	<li>{boolean} fetchLinks - Whether or not to also fetch any described link
		 *	<li>{boolean} ignore - Always fetch from the api even if the item is in the cache
		 *  <li>{boolean} isMap - The returned response is a map of items instead of a list
		 *  <li>{boolean} arrayMapPrefix - The returned response is a map of lists of individual
		 *  		items and we want to track the individual lists
		 *  <li>{number|string} customerId - The customer specific endpoint to hit
		 *  <li>{boolean} useMy - Uses the user specific api/my endpoint
		 *  <li>{string} injectedUrl - Injects the specified url after /api/
		 * </ul>
		 * @return {Promise} The state of the request
		 */
		get(currentContext, options) {
			let data;
			options = options || {};
			/* unless told to ignore the cache, and the requested data exists in the
					cache, return the requested data from the cache */
			if (!options.ignore && (options.key || options.linkedKey)) {
				data = currentContext.getters.get(options);
				if (data) {
					/* fetch related data only when the first level data existed
							in the cache, and told to getchLinks */
					if (options.fetchLinks || options.fetchExistingLinks) {
						return currentContext.dispatch('getLinks', {
							data,
							ignore: options.ignore,
							fetchExistingLinks: options.fetchExistingLinks
						});
					}
					return Promise.resolve();
				}
			}

			const id = generateRequestId(axiosInstance, options);

			/* get the requested data from the API when either:
					1) the data did not exist in the cache
					2) specifically told to request from the API by the 'ignore' cache flag */
			if (!currentRequests[id]) {
				const cancelRequest = new CancelRequest();
				// There is no existing request
				trackRequest(id, axiosInstance.get(options.key ? `${options.key}` : '', {
					params: options.params,
					baseURL: resolveUrl(axiosInstance.defaults.baseURL, options),
					cancelToken: cancelRequest.axiosToken
				})).then((response) => {
					currentContext.commit('addToCache', {
						data: response.data,
						isMap: options.isMap,
						arrayMapPrefix: options.arrayMapPrefix,
						fromAction: true
					});
					return response;
				}).finally(() => {
					currentContext.commit('removeCancelRequest', { id });
				});

				currentContext.commit('addCancelRequest', {
					cancelRequest,
					id
				});
			}

			if (options.linkedKey) {
				// There is a request, but we need it to be linked to a particular key
				currentRequests[id].then((response) => {
					currentContext.commit('addToCache', {
						data: response.data,
						linkedKey: options.linkedKey,
						isMap: options.isMap,
						arrayMapPrefix: options.arrayMapPrefix,
						fromAction: true
					});
					return response;
				});
			}
			if ((options.fetchLinks || options.fetchExistingLinks) && linkMap) {
				// There is a request but we also need to fetch api links
				/* fetch related data when told to fetchLinks, and the response
					 included related data as defined by the module's link map */
				currentRequests[id].then((response) => currentContext.dispatch('getLinks', {
					data: response.data,
					ignore: options.ignore,
					fetchExistingLinks: options.fetchExistingLinks
				}));
			}
			return currentRequests[id];
		},

		/**
		 * Fetches the links for the available key/data (in the provided options).
		 * If fetchExistingLinks is set, it will only fetch the data's link if they already
		 * exist in the associated cache.
		 * Note: You can either pass the key or the actual data (if the callee doesn't have the
		 * key)
		 *
		 * @param {object} currentContext - The context of the current api store module
		 * @param {object} options - The options for the link fetch
		 * <ul>Consists of:
		 *  <li>{string} key - The key of the cached data
		 *  <li>{object} data - The actual cached data
		 *  <li>{boolean} ignore - Whether or not to ignore any existing item in the cache
		 *  <li>{boolean} fetchExistingLinks - Fetch links only if they are already cached
		 * </ul>
		 * @returns {Promise} The state of the link fetch
		 */
		getLinks(currentContext, options) {
			const existingData = options.data || currentContext.getters.get(options);
			return Promise.all(_.map(_.isArray(existingData) ? existingData : [existingData], (data) => {
				const hasLinks = !options.fetchExistingLinks
					|| _.some(linkMap, (mutation, attributeKey) => {
						const linkKey = data[attributeKey];
						return hasLinkedDataInCache(currentContext, linkKey, mutation, data);
					});

				if (hasLinks && linkMap) {
					return fetchNecessaryApiLinks(
						currentContext,
						data,
						linkMap,
						options.ignore || options.fetchExistingLinks
					);
				}

				return Promise.resolve();
			}));
		},

		/**
		 * Posts a new object to the api and then populates the cache with it
		 *
		 * @param {object} currentContext - The context of the current api store module
		 * @param {object} options - The create options
		 * <ul>Consists of:
		 *	<li>{string} data - The data of the post
		 *	<li>{object} params - The request parameters for the post
		 *  <li>{number|string} customerId - The customer specific endpoint to hit
		 * </ul>
		 * @return {Promise} The state of the axios request. Resolved with the new api key
		 */
		create(currentContext, options) {
			return trackApiAction(axiosInstance, 'post', options.requestUrl || '', options.data, {
				params: options.params,
				baseURL: resolveUrl(axiosInstance.defaults.baseURL, options)
			}).then((response) => {
				let key = response.data ? getInScope(response.data, dataKey) : undefined;
				if (key !== undefined) {
					// The response contained the created item
					currentContext.commit('addToCache', { data: response.data });
					currentContext.state.lastModifiedKey = key;
					return Promise.resolve(key);
				}
				if (response.headers && response.headers.location) {
					// The response has a location header
					key = response.headers.location.split('/').find((part) => parseInt(part));
					currentContext.state.lastModifiedKey = key;
					return currentContext.dispatch('get', {
						key,
						ignore: true
					}).then(() => key);
				}
				// The response had neither data nor a location header
				return Promise.resolve();
			}).then(options.chainAfterRequest);
		},

		/**
		 * Updates the instance to the api and then updates the cache
		 *
		 * @param {object} currentContext - The context of the current api store module
		 * @param {object} options - The create options
		 * <ul>Consists of:
		 *	<li>{string|number} key - The api key to use
		 *	<li>{object} - The updated object
		 *	<li>{object} params - The request parameters for the put
		 *  <li>{number|string} customerId - The customer specific endpoint to hit
		 *  <li>{Object} getParams - The query parameters to use on the subsequent get request
		 * </ul>
		 * @return {Promise} The state of the request
		 */
		update(currentContext, options) {
			const callUrl = (options.key ? `${options.key}` : '') + (options.requestUrl || '');
			return trackApiAction(axiosInstance, 'put', callUrl, options.data, {
				params: options.params,
				baseURL: resolveUrl(axiosInstance.defaults.baseURL, options)
			}).then(() => currentContext.dispatch('get', {
				key: options.key,
				ignore: true,
				fetchExistingLinks: true,
				useMy: options.useMy,
				injectedUrl: options.injectedUrl,
				params: options.getParams
			})).then(() => {
				currentContext.state.lastModifiedKey = options.key;
			}).then(options.chainAfterRequest);
		},

		/**
		 * Deletes the key and removes the item from the cache. This will make an additional
		 * get request to determine if the item is no longer available and can be removed
		 * from the cache or the item has been updated to a new state
		 *
		 * @param {object} currentContext - The context of the current api store module
		 * @param {object} options - The create options
		 * <ul>Consists of:
		 *	<li>{string|number} key - The api key to use
		 *	<li>{object} params - The request parameters for the delete
		 *  <li>{number|string} customerId - The customer specific endpoint to hit
		 * </ul>
		 * @return {Promise} The state of the axios request
		 */
		delete(currentContext, options) {
			const callUrl = (options.key ? `${options.key}` : '') + (options.requestUrl || '');
			return trackApiAction(axiosInstance, 'delete', callUrl, {
				params: options.params,
				data: options.data,
				baseURL: resolveUrl(axiosInstance.defaults.baseURL, options)
			}).then(() => axiosInstance.get(`${options.key}`).then(
				(response) => {
					currentContext.state.lastModifiedKey = options.key;
					if (_.isEmpty(response.data)) {
						// If the service returned an empty object we can remove it
						currentContext.commit('removeFromCache', options.key);
					} else {
						currentContext.commit('addToCache', {
							data: response.data
						});
					}
				},
				() => {
					currentContext.state.lastModifiedKey = options.key;
					// If the service threw an exception we can remove the item
					currentContext.commit('removeFromCache', options.key);
				}
			)).then(options.chainAfterRequest);
		},

		/**
		 * Hits a single endpoint (post/put) with the given data and updates the cache with the
		 * updated item(s). E.g. Acknowledging multiple alarms
		 *
		 * @param {object} currentContext - The context of the current api store module
		 * @param {object} options - The options for the request
		 * <ul>Consists of:
		 *  <li>{string} requestUrl - The request url to hit
		 *  <li>{string} type - The type of request (post/put). Defaults to 'post'
		 *  <li>{string|number} key - The data key for an individual item
		 *  <li>{list<string|number>} keyList - The list of keys if doing a bulk request
		 *  <li>{any} data - The data to post/put for the request
		 *  <li>{object} params - The request parameters for the POST/PUT request
		 *  <li>{string|boolean} keyListParam - Specifies whether or not to use the list of keys
		 *  		on the responses body or the key/keyList from the other options
		 * </ul>
		 * @returns {Promise} The state of the api call
		 */
		request(currentContext, options) {
			const callUrl = (options.key ? `${options.key}` : '') + (options.requestUrl || '');
			return trackApiAction(
				axiosInstance,
				options.type || 'post',
				callUrl,
				options.data,
				{
					params: options.params,
					baseURL: resolveUrl(axiosInstance.defaults.baseURL, options),
					...options.requestOptions
				}
			).then((response) => {
				let keys;
				if (options.keyListParam && response.data) {
					keys = typeof options.keyListParam === 'string'
						? response.data[options.keyListParam]
						: response.data;
				} else if (response.headers && response.headers.location) {
					keys = [response.headers.location];
				}

				if (keys) {
					currentContext.state.lastModifiedKey = _.last(keys);
					return Promise.all(_.map(keys, (url) => {
						const id = url;
						if (!currentRequests[id]) {
							trackRequest(
								id,
								get(id)
							).then((linkedResponse) => {
								currentContext.commit('addToCache', {
									data: linkedResponse.data
								});
								return currentContext.dispatch('getLinks', {
									data: linkedResponse.data,
									fetchExistingLinks: true
								});
							});
						}
						return currentRequests[id];
					}));
				}

				if (options.key || options.keyList) {
					keys = options.key ? [options.key] : options.keyList;
					currentContext.state.lastModifiedKey = _.last(keys);
					return Promise.all(_.map(keys, (key) => currentContext.dispatch('get', {
						key,
						ignore: true,
						fetchExistingLinks: true
					})));
				}

				currentContext.state.lastModifiedKey = options.linkedKey || options.key;
				return Promise.resolve();
			}).then(options.chainAfterRequest);
		},

		/**
		 * Hits multiple different endpoints (post/put) with the given data and updates a single
		 * item in the cache when all of the requests are finished. E.g. Update the status of
		 * multiple tasks of a single job.
		 * Note: By default this runs sequentially instead of in parallel.
		 * E.g. Request 1 needs to finish before request 2 is sent off.
		 *
		 * @param {object} currentContext - The context of the current api store module
		 * @param {object} options - The options for the bulk request
		 * <ul>Consists of:
		 *  <li>{string|number} key - The key to update afterwards
		 *  <li>{boolean} parallel - Whether or not to run the requests in parallel
		 *  <li>{list<objects>} requests - The list of requests.
		 *    <ul>Consists of:
		 *     <li>{string} requestUrl - The request url to hit
		 *     <li>{string} type - The type of request (post/put). Defaults to 'post'
		 *     <li>{any} data - The data to post/put for the request
		 *     <li>{object} params - The request parameters for the POST/PUT request
		 *    </ul>
		 * </ul>
		 * @return {Promise} The state of all api calls
		 */
		multiRequest(currentContext, options) {
			let requests;
			if (options.parallel) {
				requests = chunkRequests(options.requests, axiosInstance);
			} else {
				requests = _.reduce(
					options.requests,
					(promise, request) => promise.then(() => createGenericRequest(request, axiosInstance)),
					Promise.resolve()
				);
			}

			return requests.then(() => {
				if (options.key) {
					currentContext.state.lastModifiedKey = options.key;
					return currentContext.dispatch('get', {
						key: options.key,
						ignore: true,
						fetchExistingLinks: true
					});
				}
				return undefined;
			}).then(options.chainAfterRequest);
		},

		/**
		 * Finds and cancels the associated cancel request
		 *
		 * @param {object} currentContext - The context of the current api store module
		 * @param {Object} payload - The action's payload
		 * @param {Object} payload.options - The request parameters
		 * @param {Number|String} payload.token - The request's token
		 */
		cancelRequest(currentContext, { options, token }) {
			const id = generateRequestId(axiosInstance, options);
			const cancelRequest = currentContext.state.cancelTokens[id];
			if (cancelRequest) {
				cancelRequest.cancel(token);
			}
		},

		/**
		 * Fetches a standard table page
		 * Uses the standard options for requesting: see the get action
		 * Example: $store.dispatch('module/getTablePage', { params: filter });
		 *
		 * Note: This will always fetch a new table page (i.e. will always ignore the cache)
		 *
		 * @param {Object} currentContext - The context of the current api store module
		 * @param {Object} payload - The action's payload.
		 * @return {Promise} The state of the api call
		 */
		getTablePage(currentContext, payload) {
			const linkedKey = JSON.stringify(payload);
			return currentContext.dispatch('get', {
				linkedKey,
				ignore: true,
				...payload
			}).then((request) => [currentContext.getters.get(linkedKey), request]);
		},

		// Optionally adds the module clean action
		...(noClean || moduleClean)
	};
}

/**
 * Creates a standard Vuex api module.
 * <ul>This will:
 *	<li>Set-up the axios instance with the given url
 *	<li>Set-up the standard getters
 *	<li>Set-up the standard actions
 *	<li>Set-up the standard mutations
 * </ul>
 *
 * @param {object} options - The api module options
 * <ul>Consists of:
 *	<li>{string} url - The api url to hook into
 *	<li>{string} dataKey - The data's unique identifier (usually dbId or id).
 *		Used for adding a fetched item to the local cache
 *	<li>{object<string, string|function>} moduleMap -
 *		Map of attributes to external module mutations
 *	<li>{object<string, string|function>} linkMap -
 *		Map of attribute links to external module mutations
 *  <li>{boolean} noClean - Disables the cleaning of the state when calling the clean functions
 * </ul>
 * @return {object} The ready to use Vuex api module
 */
export default function (options) {
	const axiosInstance = createAxiosInstance(options.url);

	return {
		state: {
			// Accessed by calling (this.)$store.getters['{module name}/get']({id})
			cache: {},

			// Accessed by calling (this.)$store.getters['{module name}/getCancelToken']({request})
			cancelTokens: {},

			// Access by calling (this.)$store.getters['{module name}/lastModifiedKey']
			lastModifiedKey: undefined,

			apiWatchers: {}
		},
		namespaced: true,

		// Called by using (this.)$store.commit({module name}/{mutation function}, ...)
		mutations: generateModuleMutations(options.dataKey, options.moduleMap, !options.noClean),

		// Called by using (this.)$store.getters[{module name}/{getter function}](...)
		getters: generateModuleGetters(axiosInstance),

		// Called by using (this.)$store.dispatch({module name}/{action function}, ...)
		actions: generateModuleActions(
			axiosInstance,
			options.dataKey,
			options.linkMap,
			options.noClean
		)
	};
}
