import { get } from '@/store/request';
import { subscribe } from '@/store/synced-subscriptions';

/**
 * User variables
 */
window.CURRENT_USER = {};

/**
 * Sets the user preference global variables
 * @param {String|Number} id - The id of the user
 * @return {Promise} - The state of the user preferences request
 */
const setUserPreferences = function (id) {
	return get(`/lineage/api/users/${id}/userPreferences/`).then((response) => {
		const preferences = response.data;
		const preferencesMap = {};
		preferences.forEach((preference) => {
			preferencesMap[preference.configOptionName] = preference.value;
		});
		if (preferencesMap.tooltipAC !== undefined) {
			window.USER_TACOPTION = preferencesMap.tooltipAC;
		}
		if (preferencesMap.unitsystem !== undefined) {
			window.USER_UNITSYSTEM = preferencesMap.unitsystem;
		}
		if (preferencesMap.hosViolationView !== undefined) {
			window.USER_HOSVIOLATIONVIEW = preferencesMap.hosViolationView;
		}
		if (preferencesMap.displayNonDutyStatus !== undefined) {
			window.USER_DISPLAY_NON_DUTY = preferencesMap.displayNonDutyStatus;
		}
		if (preferencesMap.language !== undefined) {
			window.USER_LANGUAGE = preferencesMap.language;
		}
	});
};

/**
 * Sets the current customer variable
 * @param {String|Number} id - The id of the current customer
 * @return {Promise} - The state of the customer request
 */
const setCurrentCustomer = function (id) {
	return get(`/lineage/api/customers/${id}`).then((response) => {
		window.CURRENT_CUSTOMER = {
			...response.data
		};
		window.CURRENT_USER.customerId = CURRENT_CUSTOMER.accountNumber;
	});
};

/**
 * Sets the current division variable
 * @param {String|Number} id - The id of the current division
 * @return {Promise} - The state of the division request
 */
const setCurrentDivision = function (id) {
	return get(`lineage/api/actors/division?actorId=${id}`).then((response) => {
		window.CURRENT_DIVISION = {
			...response.data
		};
	});
};

/**
 * Sets the uber admin status of the current user
 * @param {String|Number} id - The id of the current user
 * @return {Promise} - The state of the group request
 */
const setUberAdminStatus = function (id) {
	return get(`/lineage/api/actors/${id}/groups`).then((response) => {
		CURRENT_USER.isUberAdmin = response.data.findIndex((group) => group.name === 'Uber Admins') > -1;
	});
};

/**
 * Sets the user's support center
 * @param {String|Number} id - The id of the current user's customer
 * @return {Promise} - The state of the support center request
 */
const setUserSupportCenter = function (id) {
	return get(`/lineage/api/customers/${id}/supportCenter`).then((response) => {
		CURRENT_USER.supportCenter = response.data;
	});
};

/**
 * Sets the user's application features
 * @return {Promise} - The state of the feature request
 */
const setUserApplicationFeatures = function () {
	return get('/lineage/api/my/features').then((response) => {
		const permissionMap = {};
		response.data.forEach((permission) => {
			permissionMap[`${permission.type}.${permission.name}`] = true;
		});
		CURRENT_USER.appFeatures = permissionMap;
	});
};

/**
 * Converts the boolean values returned from strings to actual booleans
 */
const convertToTrueBooleans = function () {
	CURRENT_USER.active = CURRENT_USER.active === 'true' || CURRENT_USER.active === true;
	CURRENT_USER.isDivision = CURRENT_USER.isDivision === 'true' || CURRENT_USER.isDivision === true;
	CURRENT_USER.isDriver = CURRENT_USER.isDriver === 'true' || CURRENT_USER.isDriver === true;
	CURRENT_USER.sendPasswordEmail = CURRENT_USER.sendPasswordEmail === 'true' || CURRENT_USER.sendPasswordEmail === true;
	CURRENT_USER.emailValid = CURRENT_USER.emailValid === 'true' || CURRENT_USER.emailValid === true;
};

/**
 * Returns if the user has access to the specified feature type and name.
 * If not already fetched the users list of features will be pulled.
 *
 * @param {string} featureType - The application feature type
 * @param {string} featureName - The application feature name
 * @return {boolean} true if the user has access to the specified feature type and name
 */
CURRENT_USER.hasAccessToFeature = function (featureType, featureName) {
	if (CURRENT_USER.isUberAdmin) {
		return true;
	}

	return CURRENT_USER.appFeatures[`${featureType}.${featureName}`] === true;
};

/**
 * Fetches and initializes the ORIGINAL_USER & ORIGINAL_CUSTOMER variable
 *
 * @return {Promise} - The state of the request
 */
const setOriginalVariables = function () {
	return get('/lineage/api/users/original').then((response) => {
		if (response.data) {
			window.ORIGINAL_USER = response.data;
			window.ORIGINAL_CUSTOMER = { dbId: response.data.customerId };
		} else {
			window.ORIGINAL_USER = undefined;
			window.ORIGINAL_CUSTOMER = undefined;
		}
	});
};

/**
 * Sets the subscription interval
 *
 * @returns {Promise} The state of the request
 */
const setSubscriptionInterval = function () {
	return get('/ui/pubsub_interval.json', { baseURL: '' }).then((response) => {
		window.SUBSCRIPTION_INTERVALS = response.data;
		window.SUBSCRIPTION_INTERVAL = window.SUBSCRIPTION_INTERVALS.DEFAULT;
	}, () => {});
};

/**
 * Initializes the current user variables
 *
 * @param {Object} options - The user variable options to disable specific requests
 * <ul>Consists of:
 *  <li>{Boolean} disableSubscription - Ignores the subscription interval configured by the server
 * </ul>
 * @return {Promise} - The state of the profile request
 */
export default function (options = {}) {
	return get('/lineage/api/my/profile', {
		headers: { 'X-Requested-With': 'XMLHttpRequest' },
		// Ignore the default interceptors, so we can redirect without a session expired message
		validateStatus: false
	}).then((response) => {
		if (response.status === 401) {
			window.location.href = `/lineage/callback?url=${encodeURIComponent(window.location.href)}`;
			return Promise.reject(new Error('Authentication error'));
		}
		if (response.status !== 200) {
			window.location.href = `/login.html?success=${encodeURIComponent(window.location.href)}&error=errors.unavailable`;
			return Promise.reject(new Error('Profile error'));
		}
		window.CURRENT_USER = {
			...window.CURRENT_USER,
			...response.data
		};
		CURRENT_USER.dbId = parseInt(CURRENT_USER.dbId);
		CURRENT_USER.userCustomerDBid = parseInt(response.data.customerId);
		window.USER_TIMEZONE_NAME = response.data.timeZone;
		convertToTrueBooleans();

		return Promise.all([
			setUserPreferences(response.data.dbId),
			setCurrentCustomer(response.data.customerId),
			setCurrentDivision(response.data.dbId),
			setUberAdminStatus(response.data.dbId),
			setUserSupportCenter(response.data.customerId),
			setUserApplicationFeatures(),
			setOriginalVariables(),
			options.disableSubscription ? undefined : setSubscriptionInterval().then(() => {
				subscribe(setSubscriptionInterval, 'SYSTEM');
			})
		]);
	});
}
