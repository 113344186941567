import Vue from 'vue';

/** {string} The get string constant */
const GET = '/get';

const API_STORE_HELPER = {
	/**
	 * Executed when the plugin is used by Vue (Vue.use(PLUGIN))
	 * Adds the $storeGet method to Vue's prototype
	 *
	 * @param {Vue} vue - The vue prototype
	 */
	install(vue) {
		/**
		 * Standard method for retrieving items from the store and dispatching if those items
		 * were not populated.
		 * Note: The dispatch method will be called with the getParams if the dispatchParams
		 * were not set
		 * @param {object} options - The options for the store get
		 * <ul>Consists of:
		 *	<li>{string} module - The module to preform (e.g. actors, alarmConfigs, etc)
		 *	<li>{object} getParams - The parameters to call the store's getter method
		 *	<li>{boolean} forceDispatch - When true this will always call the dispatch method
		 *	<li>{object} dispatchParams - The parameters to call the store's dispatch method
		 *  <li>{function} beforeDispatch - Called before the dispatch action is initiated
		 *	<li>{function} dispatchSuccess - Called when the dispatch action succeeds
		 *	<li>{function} dispatchFailure - Called when the dispatch action fails
		 *	<li>{function} dispatchFinally - Called when the dispatch completes regardless of
		 *		success or failure
		 * <ul>
		 * @return {object} The result from the store's getter method
		 * @deprecated
		 */
		vue.prototype.$storeGet = function (options) {
			const result = this.$store.getters[options.module + GET](options.getParams);
			if (!result || options.forceDispatch) {
				if (options.beforeDispatch) {
					options.beforeDispatch();
				}

				this.$store.dispatch(
					options.module + GET,
					options.dispatchParams || options.getParams
				).then(options.dispatchSuccess, options.dispatchFailure)
					.then(options.dispatchFinally, options.dispatchFinally);
			}
			return result;
		};
	}
};

/**
 * Plugin declaration for our api modules
 *
 * <ul>Adds to the Vue prototype
 *	<li>{function} $storeGet - Simplifies getting and populating the store module with information
 * <ul>
 */
Vue.use(API_STORE_HELPER);
