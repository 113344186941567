import createAxiosInstance from '@/store/helpers/create-axios-instance';

const requestInstance = createAxiosInstance();

/**
 * {axios} The standard axios instance
 */
export default requestInstance;

/**
 * Sets the base url of the general request instnace
 *
 * @param {string} baseURL - The base url for all general requests ('/lineage', '/services')
 * @param {object} headers - The custom headers to add to all requests
 */
export const setDefaultApiPrefix = function (baseURL, headers) {
	requestInstance.defaults.baseURL = baseURL;
	Object.assign(requestInstance.defaults.headers.common, headers);
};

/** {function} The specific get api call */
export const get = requestInstance.get;

/** {function} The specific post api call */
export const post = requestInstance.post;

/** {function} The specific put api call */
export const put = requestInstance.put;

/** {function} The specific remove/delete api call */
export const remove = requestInstance.delete;
