/** Mapping of available languages in our system to their file name  */
const AVAILABLE_LANGUAGES = {
	'en-us': 'en-us',
	en: 'en-us',
	fr: 'fr',
	es: 'es'
};

/**
 * Helper function to find the correct language for the user
 * Defaults to english if we do not have the user's browser language
 */
export function findLanguage() {
	if (AVAILABLE_LANGUAGES[window.USER_LANGUAGE]) {
		return AVAILABLE_LANGUAGES[window.USER_LANGUAGE];
	}

	if (window.CURRENT_USER
		&& CURRENT_USER.hasAccessToFeature
		&& CURRENT_USER.hasAccessToFeature('user_permission', 'internationalization')
	) {
		const windowLanguage = window.navigator.language.toLowerCase();
		if (AVAILABLE_LANGUAGES[windowLanguage]) {
			return AVAILABLE_LANGUAGES[windowLanguage];
		}

		/**
		 * If the user is using an alternate version of a language (ex. en-US or fr-CA)
		 * it will default to the base language (ex. fr or en) if we don't have the alternate
		 */
		const baseLanguage = AVAILABLE_LANGUAGES[windowLanguage.split('-')[0]];
		if (baseLanguage) {
			return baseLanguage;
		}
	}

	return 'en-us';
}

/**
 * Merges the base i18n entries with the localized entries
 *
 * @param {Object} base - The base entry
 * @param {Object} locale - The localized entry
 * @returns {Object} The merged base and localized entries
 */
function mergeLocales(base, locale) {
	const merged = {};
	Object.keys(base).forEach((key) => {
		if (typeof base[key] === 'object') {
			merged[key] = locale[key] === undefined ? base[key] : mergeLocales(base[key], locale[key]);
		} else {
			merged[key] = locale[key] === undefined ? base[key] : locale[key];
		}
	});

	return merged;
}

/**
 * Loads the specific language module using en-us as the base
 *
 * @param {String} [module] - The localized module to load
 * @returns {Promise<{}>} Resolved with the localized entries
 */
export async function loadLanguage(module) {
	const locale = findLanguage();
	document.querySelector('html').setAttribute('lang', locale);
	if (window.moment) {
		moment.locale(locale);
	}

	const [baseModule, localizedModule] = await Promise.all([
		import(`@/internationalization/locales/en-us/en-us${module ? '_' : ''}${module || ''}.json`),
		locale !== 'en_us' ? import(`@/internationalization/locales/${locale}/${locale}${module ? '_' : ''}${module || ''}.json`) : {}
	]);

	if (localizedModule) {
		return mergeLocales(baseModule.default, localizedModule.default);
	}

	return baseModule.default;
}
