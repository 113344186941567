window.moment = require('@/lib/moment/moment-with-locales.min');

require('@/lib/moment/moment-range.min');
require('@/lib/moment/moment-duration-format');
require('@/lib/moment/moment-timezone-with-data-1970-2030.min');

// Only use the guess function to ensure the returned time zone is supported by the moment library
const timeZone = moment.tz.guess();

if (timeZone) {
	moment.tz.setDefault(timeZone);
}
