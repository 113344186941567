import axios from '@/lib/Axios_0.18.0.min';
import { getErrorMessage } from '@/utils/text';

/** {string} The api prefix to use. This will be manually set when the store is being initialized */
let apiPrefix;

/** {object} The headers to use. This will be manually set when the store is being initialized */
let headers;

const PARAM_ASSIGNMENT = '=';

/**
 * Encodes the value using {@link encodeURIComponent} with special exceptions:
 * <ul>Exceptions:
 *  <li>Changes ' ' (space) to '+'
 *  <li>Unencodes '%2C' (comma) to ','
 * </ul>
 *
 * @param {String} value - The value to encode
 * @return {string} The encoded api value
 */
function encodeValue(value) {
	return encodeURIComponent(value)
		.replace(/%2C/gi, ',')
		.replace(/%20/g, '+');
}

/**
 * Serializes the given value to the unencoded value
 * <ul>Serializes
 *  <li>Date using {@link Date.toISOString}
 *  <li>Object using {@link JSON.stringify}
 * </ul>
 *
 * @param {*} value - The value to serialize
 * @return {string} The serialized value
 */
function serializeValue(value) {
	if (_.isDate(value)) {
		return value.toISOString();
	}
	if (_.isObject(value)) {
		return JSON.stringify(value);
	}

	return value;
}

/**
 * Custom serializer to encode query parameters for api requests
 *
 * @param {Object} queryParams - The request's query parameters
 * @return {string} The serialized/encoded query parameter string
 */
export function paramsSerializer(queryParams) {
	const result = [];
	_.each(queryParams, (value, key) => {
		if (value !== null && typeof value !== 'undefined') {
			if (_.isArray(value)) {
				value = _.map(value, serializeValue).join(',');
			} else {
				value = serializeValue(value);
			}
			result.push(encodeValue(key) + PARAM_ASSIGNMENT + encodeValue(value));
		}
	});

	return result.join('&');
}

/**
 * Creates an axios instance based on the given url.
 * For now assumes all interactions (get/post/put/delete) are json
 *
 * @param {string} url - The url to use
 * @return {axios} The created instance
 */
export default function (url) {
	const axiosInstance = axios.create({
		baseURL: apiPrefix ? apiPrefix + url : url,
		headers: {
			common: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				...headers
			}
		},
		paramsSerializer
	});

	// Format the api failures into something useful before returning it to the callee
	axiosInstance.interceptors.response.use(
		// API Successes
		_.identity,

		// API Failures
		(error) => {
			// Handle service redirection
			if (error
				&& error.response
				&& axiosInstance.defaults
				&& axiosInstance.defaults.baseURL
				&& axiosInstance.defaults.baseURL.startsWith('/lineage')
			) {
				if (error.response.status === 401) {
					window.location.href = `/login.html?error=errors.expired&success=${encodeURIComponent(window.location)}`;
				} else if (error.response.status > 500 && error.response.status !== 504) {
					window.location.href = `/login.html?error=errors.unavailable&success=${encodeURIComponent(window.location)}`;
				}
			}

			let errorMessage;
			// locate the error message in the response
			if (error
				&& error.response
				&& error.response.data
				&& error.response.data.errorMessage
			) {
				errorMessage = error.response.data.errorMessage;
			}

			// If there was no error message, add the default from i18n.
			if (!errorMessage) {
				error.response = { data: {} };
				error.response.data = { errorMessage: window.i18n ? i18n.API_EXCEPTIONS.GENERAL : 'A problem occurred' };
			} else {
				// use the util to lookup the error message and translate
				error.response.data.errorMessage = getErrorMessage(
					{ responseText: errorMessage },
					'{0}'
				);
			}

			return Promise.reject(error);
		}
	);
	return axiosInstance;
}

/**
 * Set's up the api prefix and custom headers to use for axios requests
 *
 * @param {string} prefix - The api prefix
 * @param {object} customHeaders - The custom headers for the request
 */
export const setAxiosDefaults = function (prefix, customHeaders) {
	apiPrefix = prefix;
	headers = customHeaders;
};
