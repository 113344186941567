import { setDefaultApiPrefix } from '@/store/request';
import { setAxiosDefaults } from '@/store/helpers/create-axios-instance';

/**
 * Sets all of the necessary prefixes in a single place
 *
 * @param {string} apiPrefix - The api prefix to use
 * @param {object} headers - The custom headers to add to all requests
 */
export default function (apiPrefix, headers) {
	setDefaultApiPrefix(apiPrefix, headers);
	setAxiosDefaults(apiPrefix, headers);
}
