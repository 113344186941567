import createAxiosInstance from '@/store/helpers/create-axios-instance';

/** {string}  Prefix for the keys that is automatically stripped out */
const keyPrefix = 'config.option.';

/** {RegExp} Detects if the preference starts with landmark_group */
const landmarkGroupRgx = /^landmark_group_/;

/**
 * Generates the user preference getters
 * <ul>This provides the following
 *  <li>{function} get - Gets the key from the cache
 *  <li>{function} getLandmarkGroupPreferences - Gets the map of landmark group map preferences
 * </ul>
 *
 * @return {object} The available getters
 */
export const generateModuleGetters = function () {
	return {
		/**
		 * Gets the key/list of keys from the cache.
		 * If no key is specified this will return everything from the cache
		 *
		 * @param {object} currentState - The current state of the module
		 * @return {function} The cache accessor function
		 * <ul>Parameters
		 *  <li>{string|array<string>} params - The key/list of keys to get
		 * </ul>
		 */
		get(currentState) {
			return function (params) {
				const keys = _.isObject(params) && !_.isArray(params) ? params.key : params;
				let item;

				if (typeof keys === 'string' && keys !== 'all') {
					item = currentState.cache[keys];
				} else {
					item = {};
					_.each(!keys || keys === 'all' ? _.keys(currentState.cache) : keys, (key) => {
						item[key] = currentState.cache[key];
					});
				}

				return item;
			};
		},

		/**
		 * Gets the map of landmark group map preferences
		 *
		 * @param {object} currentState - The current state of the module
		 * @return {object} The landmark group preferences
		 */
		getLandmarkGroupPreferences(currentState) {
			const items = {};
			_.each(currentState.cache, (value, key) => {
				if (landmarkGroupRgx.test(key)) {
					items[key] = value;
				}
			});

			return items;
		}
	};
};

/**
 * Generates the standard mutations for interacting with the user preference cache
 * <ul>This provides the following mutations:
 *	<li>addToCache
 * </ul>
 *
 * @return {object} The standard module mutations
 */
export const generateModuleMutations = function () {
	return {
		/**
		 * Adds the payload map to the cache
		 *
		 * @param {object} currentState - The current state of the module
		 * @param {object} payload - The key/value map of user preferences
		 */
		addToCache(currentState, payload) {
			_.each(payload, (value, key) => {
				if (key.indexOf(keyPrefix) === 0) {
					key = key.substring(keyPrefix.length);
				}
				try {
					Vue.set(currentState.cache, key, JSON.parse(value));
				} catch (e) {
					// Setting wasn't a JSON value
					Vue.set(currentState.cache, key, value);
				}
			});
		}
	};
};

/**
 * Generates the api action methods that are hooked up with the modules cache
 * <ul>This provides the following apis:
 *	<li>get - Populates the entire cache
 *	<li>update - Updates a specific preference before updating the cache
 * </ul>
 *
 * @param {axios} axiosInstance - The axios instance to use
 * @return {object} - The standard api interactions
 */
export const generateModuleActions = function (axiosInstance) {
	return {
		/**
		 * Populates the cache with all user preferences.
		 *
		 * @param {object} currentContext - The context of the user preference store module
		 * @param {object} options - The get options
		 * <ul>Consists of:
		 *  <li>{boolean} ignore - Always fetch from the api even if the cache is populated
		 * </ul>
		 * @return {Promise} The state of the request
		 */
		get(currentContext, options) {
			if ((!options || !options.ignore) && !_.isEmpty(currentContext.state.cache)) {
				return Promise.resolve();
			}

			return axiosInstance.get(`${CURRENT_USER.dbId}/userPreferences`).then(({ data }) => {
				const preferences = {};
				_.each(data, (preference) => {
					preferences[preference.configOptionName] = preference.value;
				});
				currentContext.commit('addToCache', preferences);
			});
		},

		/**
		 * Updates given user preferences
		 *
		 * @param {object} currentContext - The context of the user preference store module
		 * @param {object} options - The map of updated user preference key/value pairs
		 * @return {Promise<unknown>} The state of the request
		 */
		update(currentContext, options) {
			return axiosInstance.post(
				`${CURRENT_USER.dbId}/userPreferences`,
				_.map(options, (value, key) => ({
					configOptionName: key,
					value: typeof value === 'object' ? JSON.stringify(value) : value
				}))
			).finally(() => {
				currentContext.commit('addToCache', options);
			});
		}
	};
};

/**
 * Creates the standard user-preference store module
 * <ul>This will:
 *	<li>Set-up the axios instance
 *	<li>Set-up the standard getters
 *	<li>Set-up the standard actions
 *	<li>Set-up the standard mutations
 * </ul>
 *
 * @return {object} The ready to use Vuex api module
 */
export default function () {
	const axiosInstance = createAxiosInstance('/api/users');
	return {
		state: { cache: {} },
		namespaced: true,
		getters: generateModuleGetters(),
		mutations: generateModuleMutations(),
		actions: generateModuleActions(axiosInstance)
	};
}
