/**
 * Takes the given string and checks if it is in URL format
 *
 * @param {string} string - The value to check for URL formatting
 * @returns {boolean} Whether or not the string given is a URL
 */
export function urlCheck(string) {
	const strRegex = '^((https|http)?://)';
	const re = new RegExp(strRegex);
	return re.test(string);
}

const CSV_CHARACTERS = /[,"\n]/;
/**
 * Helper function to escape CSV formatting variables
 *
 * <ul>Transforms the following
 *  <li>abc - abc
 *  <li>abc,def - "abc,def"
 *  <li>a"c - "a""c"
 *  <li>"abc","def" - """abc"",""def"""
 * </ul>
 *
 * @param {String} string - The string to wrap
 * @returns {string} The csv safe string
 */
export function escapeCSV(string) {
	if (!string) {
		return '';
	}

	return CSV_CHARACTERS.test(string)
		? `"${string.replace(/"/g, '""')}"`
		: string;
}

const DOUBLE_QUOTE = /""/g;
/**
 * Helper function to unescape CSV formatting variables
 *
 * <ul>Transforms the following
 *  <li>abc - abc
 *  <li>"abc,def" - abc,def
 *  <li>"a""c" - a"c
 *  <li>"""abc"",""def""" - "abc","def"
 * </ul>
 *
 * @param {String} string - The string to wrap
 * @returns {string} The csv safe string
 */
export function unescapeCSV(string) {
	if (!string) {
		return '';
	}
	if (string[0] === '"' && string[string.length - 1] === '"') {
		string = string.slice(1, string.length - 1);
	}
	return string.replace(DOUBLE_QUOTE, '"');
}

/**
 * Gets the formatted error message from a standard jsXHR object
 *
 * @param {jqXHR} jqXHR - The failed response
 * @param {string} message - The string to format with the error message
 * @return {string} The formatted response
 * <ul>It will use
 *	<li>i18n.API_EXCEPTIONS.GENERAL - The jqXHR doesn't have responseText
 *	<li>i18n.API_EXCEPTIONS.MESSAGE w/ Full response text - The response text failed to parse
 *	<li>i18n.API_EXCEPTIONS.MESSAGE w/ response text's errorMessage otherwise
 * </ul>
 */
export function getErrorMessage(jqXHR, message) {
	message = message || i18n.API_EXCEPTIONS.MESSAGE;
	if (jqXHR && jqXHR.responseText) {
		try {
			return message.replace(
				'{0}',
				JSON.parse(jqXHR.responseText).errorMessage
			);
		} catch (e) {
			if (jqXHR.responseText[0] !== '<') {
				return message.replace(
					'{0}',
					i18n.API_EXCEPTIONS[jqXHR.responseText] || jqXHR.responseText
				);
			}
		}
	}
	return i18n.API_EXCEPTIONS.GENERAL;
}

/**
 * Gets the formatted error message from a standard axios response object
 * Delegates to getErrorMessage
 *
 * @param {string|object} request - The axios response
 * @param {string} message - The string to format with the error message
 * @returns {string} The formatted response.
 */
export function parseAxiosMessage(request, message) {
	return getErrorMessage(
		{
			responseText: request === undefined || request === null || typeof request === 'string'
				? request
				: request.response.data.errorMessage
		},
		message
	);
}

/**
 * Strips leading slashes from a string
 * @param {string} string string to perform check on
 * @returns {string} string sans leading slash
 */
export function stripLeadingSlash(string) {
	if (string[0] === '/') {
		return string.substring(1, string.length);
	}
	return string;
}

const lineEndingRGX = /(\r\n|\n|\r)/gm;
/**
 * Strips new-lines from the given string and replaces them with the specified delimiter
 *
 * @param {string} lines - The string to replace
 * @param {string} delimiter - The delimiter to use
 * @return {string} The replaced string
 */
export function mergeLines(lines, delimiter) {
	return lines.replace(lineEndingRGX, delimiter);
}

/**
 * Convert number to hex string
 * @param {number} number
 * @returns {string}
 */
export function numToHex(number) {
	let hex = '';
	if (number !== undefined) {
		hex = number.toString(16);
	}
	return hex.length === 1 ? `0${hex}` : hex;
}

/**
 * Convert set of RGB numbers to hex string
 * Used by ptcolors to get hex values
 * @param {number} r
 * @param {number} g
 * @param {number} b
 * @returns {string}
 */
export function rgbToHex(r, g, b) {
	return `#${numToHex(r)}${numToHex(g)}${numToHex(b)}`;
}

/**
 * Formats the given rank to ordinal numbering (i.e. 1st, 2nd, 3rd, 4th, etc)
 * @param {number} rank The rank that needs to be formatted to ordinal numbering
 * @return {string} The formatted string to be displayed
 */
export function ordinalNumbering(rank) {
	if (rank < 10 || rank > 20) {
		switch (rank % 10) {
		case 1:
			return `${rank}st`;
		case 2:
			return `${rank}nd`;
		case 3:
			return `${rank}rd`;
		default:
			return `${rank}th`;
		}
	} else {
		return `${rank}th`;
	}
}

/** {Object} The map of characters to escape */
const ESCAPED_CHARACTERS = {
	'<': '&lt;',
	'>': '&gt;',
	'"': '&quot;',
	'&': '&amp;',
	'\'': '&#39;',
	'`': '&#x60;',
	'=': '&#x3D;'
};

/** {Regex} The regex to match the characters to escape */
const ESCAPED_REGEX = /[<>"&'`=]/g;

/**
 * Scrubs the value for html characters
 *
 * @param {String} text - The string to escape html values
 * @returns {String} The text with the escaped values
 */
export function escapeHTML(text) {
	return text.replace(ESCAPED_REGEX, (char) => ESCAPED_CHARACTERS[char]);
}

/** {Regex} The regex to match tags that should be removed */
const STRIP_HTML = /<\/?[^>]+>/g;

/**
 * Removes all tags from a string
 *
 * @param {String} text - The string to remove tags from
 * @returns {String} The text with the tags removed
 */
export function stripHMTL(text) {
	return text.replace(STRIP_HTML, '');
}

/**
 * Truncates the text to the desired length
 *
 * @param {String} text - The string to truncate
 * @param {Number} length - The max length of the string
 * @param {String} [characters] - The string to append when truncation occurs
 * @returns {String} The truncated text
 */
export function truncate(text, length, characters = '...') {
	return text.length > length ? text.slice(0, length - characters.length) + characters : text;
}

/** {Regex} Detects the edges of words */
const WORD_REGEX = /[-_]|([a-z0-9])([A-Z])/g;

/** {Regex} Detects the first letter of a word */
const FIRST_LETTER_REGEX = /(?:^|\s)\S/g;

/**
 * Humanizes a string: (a-b, a_b, aB) => a b
 * If possible, use i18n / label / display names
 *
 * @param {String} text - The text to humanize
 * @param {Boolean} [titleize] - Whether to capitalize the first letter of each word
 * @returns {string} The humanized text
 */
export function humanize(text, titleize) {
	const humanized = text.replace(WORD_REGEX, '$1 $2').toLowerCase();
	if (titleize && humanized) {
		return humanized.replace(FIRST_LETTER_REGEX, (c) => c.toUpperCase());
	}

	return humanized;
}
