import createAxiosInstance from '@/store/helpers/create-axios-instance';

/** {Number} The number of milliseconds to delay the app state requests */
const APP_STATE_DELAY = 100;

/** {String} The html attribute to indicate the app state request is running */
const HTML_ATTRIBUTE_NAME = 'data-qa-app-state';

/** {String} The html value for the app state attribute */
const HTML_ATTRIBUTE_VALUE = 'updating';

let batchCache = {};
let batchedRequest;

/**
 * Hits the app state api with the passed in data
 *
 * @param {axios} axiosInstance - The request instance for interacting with the api
 * @param {Object} data - The app state information to send to the pai
 * @return {Promise} The state of the request
 */
function sendAppStateRequest(axiosInstance, data) {
	return axiosInstance.post(
		`${CURRENT_USER.dbId}/applicationStates`,
		_.mapObject(
			data,
			(value) => (typeof value === 'string' ? value : (JSON.stringify(value) || ''))
		)
	);
}

/**
 * Creates, or uses the pending, batched api request
 * Note: this will delay the request by {@link APP_STATE_DELAY}
 *
 * @param {axios} axiosInstance - The request instance for interacting with the api
 * @return {Promise} The state of the batched request
 */
function delayAppStateRequest(axiosInstance) {
	if (!batchedRequest) {
		batchedRequest = new Promise((resolve, reject) => {
			setTimeout(() => {
				if (Object.keys(batchCache).length) {
					sendAppStateRequest(axiosInstance, batchCache).then(resolve, reject);
				} else {
					resolve();
				}

				// Reset the batching request information after the request has been sent
				batchCache = {};
				batchedRequest = undefined;
			}, APP_STATE_DELAY);
		}).finally(() => {
			document.body.removeAttribute(HTML_ATTRIBUTE_NAME);
		});
	}

	return batchedRequest;
}

/**
 * Stores the specified app state data in the api.
 * Note: This will batch the different requests together
 *
 * @param {axios} axiosInstance - The request instance for interacting with the api
 * @param {Object} data - The app state key/value to store
 * @return {Promise} The state of the (delayed) request
 */
function storeAppState(axiosInstance, data) {
	Object.assign(batchCache, data);

	return delayAppStateRequest(axiosInstance);
}

/**
 * Generates the app state getters
 * <ul>This provides the following
 *  <li>{function} get - Gets the key from the cache
 * </ul>
 *
 * @return {object} The available getters
 */
export function generateModuleGetters() {
	return {
		/**
		 * Gets the key/list of keys from the cache.
		 * If no key is specified this will return a clone of the cache
		 *
		 * @param {object} currentState - The current state of the module
		 * @return {function} The cache accessor function
		 * <ul>Parameters
		 *  <li>{string|{ key: String, private: Boolean }} params - The key to get from the cache
		 * </ul>
		 */
		get(currentState) {
			return function (params) {
				const key = _.isObject(params) ? params.key : params;
				const result = key
					? currentState.cache[key]
					: JSON.parse(JSON.stringify(currentState.cache));

				// Stringify/parse is the safest way to create a private instance
				return params && params.private && result !== undefined
					? Vue.observable(JSON.parse(JSON.stringify(result)))
					: result;
			};
		}
	};
}

/**
 * Generates the standard mutations for interacting with the app state cache
 * <ul>This provides the following mutations:
 *	<li>addToCache
 * </ul>
 *
 * @return {object} The standard module mutations
 */
export function generateModuleMutations() {
	return {
		/**
		 * Adds the payload map to the cache
		 *
		 * @param {object} currentState - The current state of the module
		 * @param {object} payload - The key/value map of the user app states
		 */
		addToCache(currentState, payload) {
			_.each(payload, (value, key) => {
				try {
					Vue.set(
						currentState.cache,
						key,
						typeof value === 'string' ? JSON.parse(value) : value
					);
				} catch (e) {
					// Setting wasn't a JSON value
					Vue.set(currentState.cache, key, value);
				}
			});
		}
	};
}

/**
 * Generates the api action methods that are hooked up with the modules cache
 * <ul>This provides the following apis:
 *	<li>get - Populates the entire cache
 *	<li>update - Updates a specific app state before updating the cache
 * </ul>
 *
 * @param {axios} axiosInstance - The axios instance to use
 * @return {object} - The standard api interactions
 */
export function generateModuleActions(axiosInstance) {
	return {
		/**
		 * Populates the cache with the user's app state.
		 *
		 * @param {object} currentContext - The context of the app state store module
		 * @param {object} options - The get options
		 * <ul>Consists of:
		 *  <li>{boolean} ignore - Always fetch from the api even if the cache is populated
		 * </ul>
		 * @return {Promise} The state of the request
		 */
		get(currentContext, options) {
			if ((!options || !options.ignore) && !_.isEmpty(currentContext.state.cache)) {
				return Promise.resolve();
			}

			return axiosInstance.get(`${CURRENT_USER.dbId}/applicationStates`).then(({ data }) => {
				currentContext.commit('addToCache', _.mapObject(data, (value) => {
					if (value) {
						try {
							return JSON.parse(value);
						} catch (e) {
							// Setting wasn't a JSON value
							return value;
						}
					}
					return undefined;
				}));
			});
		},

		/**
		 * Updates given app state entry
		 * Example: store.dispatch('appState/update', { 'user.something': 'abc' })
		 *
		 * @param {object} currentContext - The context of the app state store module
		 * @param {object} data - The entry to update
		 * @return {Promise} The state of the request
		 */
		update(currentContext, data) {
			currentContext.commit('addToCache', data);

			document.body.setAttribute(HTML_ATTRIBUTE_NAME, HTML_ATTRIBUTE_VALUE);

			return storeAppState(axiosInstance, data);
		}
	};
}

/**
 * Creates the standard app state store module
 * <ul>This will:
 *	<li>Set-up the axios instance
 *	<li>Set-up the standard getters
 *	<li>Set-up the standard actions
 *	<li>Set-up the standard mutations
 * </ul>
 *
 * @return {object} The ready to use Vuex api module
 */
export default function () {
	const axiosInstance = createAxiosInstance('/api/users/');

	return {
		state: { cache: {} },
		namespaced: true,
		getters: generateModuleGetters(),
		mutations: generateModuleMutations(),
		actions: generateModuleActions(axiosInstance)
	};
}
