const FILE_ENDING = '.png';
const ICON_FILE_PATH = '/images/event-icons/';
const DEFAULT_ICON_FILE_NAME = 'Gear';

const EVENT_MODELS_TO_FILE = {};

const OBSERVED_ATTRIBUTES = ['eventmodelid', 'event-model-id', 'class'];

/**
 * Populates the event model to icon file name
 *
 * @param eventModelId - The event model id
 * @return {Promise} The state of the request
 */
function populateEventModels(eventModelId) {
	return window.PT_STORE.dispatch('eventModels/get', {
		key: eventModelId
	}).then(() => {
		const eventModel = window.PT_STORE.getters['eventModels/get'](eventModelId);
		EVENT_MODELS_TO_FILE[eventModelId] = eventModel.iconSetFileName;
	});
}

/**
 * Custom class definition for an event image
 * <ul>This will set the image based on:
 *  <li>{Number|String} eventModelId - The id of the event model
 * </ul>
 */
export default class extends HTMLElement {
	constructor() {
		super();
		this.eventImage = document.createElement('IMG');

		this.updateImage();

		// Hide this element, so it doesn't interfere with the flow of the document
		setTimeout(() => {
			this.style.display = 'none';
		}, 1);
	}

	/**
	 * @returns {string} The name of the custom tag
	 */
	static get tagName() {
		return 'event-img';
	}

	/**
	 * @returns {string[]} The attributes that trigger the attributeChangedCallback
	 */
	static get observedAttributes() {
		return OBSERVED_ATTRIBUTES;
	}

	/**
	 * Called when the watched attributes update.
	 * Synchronizes the image with the updated attributes
	 */
	attributeChangedCallback() {
		this.updateImage();
	}

	/**
	 * Called when the custom element is attached to the document
	 * Inserts the image element
	 */
	connectedCallback() {
		if (this.parentNode) {
			this.parentNode.insertBefore(this.eventImage, this);
		}
	}

	/**
	 * Called when the custom element is moved between documents
	 * Updates the location of the image element
	 */
	adoptedCallback() {
		if (this.parentNode) {
			this.parentNode.insertBefore(this.eventImage, this);
		}
	}

	/**
	 * Called when the custom element is removed from the document
	 * Removes the image element
	 */
	disconnectedCallback() {
		if (this.eventImage && this.eventImage.parentNode) {
			this.eventImage.parentNode.removeChild(this.eventImage);
		}
	}

	/**
	 * Updates the image to match the updated attributes
	 */
	updateImage() {
		this.eventImage.className = `event-image-icon ${this.className}`;
		const eventModelId = this.getAttribute('event-model-id') || this.getAttribute('eventModelId');

		if (EVENT_MODELS_TO_FILE[eventModelId]) {
			this.eventImage.src = ICON_FILE_PATH
				+ (EVENT_MODELS_TO_FILE[eventModelId] || DEFAULT_ICON_FILE_NAME)
				+ FILE_ENDING;
		} else if (eventModelId) {
			populateEventModels(eventModelId).then(() => {
				this.eventImage.src = ICON_FILE_PATH
					+ (EVENT_MODELS_TO_FILE[eventModelId] || DEFAULT_ICON_FILE_NAME)
					+ FILE_ENDING;
			});
		}
	}
}
