import axios from '@/lib/Axios_0.18.0.min';

/**
 * A single request cancel token that will only cancel the request if all usages want the request to
 * be canceled.
 * Uses the axios CancelToken underneath: https://github.com/axios/axios#cancellation
 *
 * Example:
 * const cancelRequest = new CancelRequest();
 * axios.get('/some/url', {
 *    cancelToken: cancelRequest.axiosToken
 * })
 * ...
 * const token = cancelRequest.requestToken();
 * ...
 * cancelRequest.cancel(token);
 */
export default class CancelRequest {
	/** {Object<Number, Boolean>} The map of listeners */
	requesters = {};

	/** {Object} The axios cancel token source object */
	source = undefined;

	/** {Promise} The axios cancel token */
	axiosToken = undefined;

	/**
	 * Creates a new instance of {@link CancelRequest}
	 *
	 */
	constructor() {
		this.source = axios.CancelToken.source();
		this.axiosToken = this.source.token;
	}

	/**
	 * Adds a new request listener
	 *
	 * @return {Number} The listener's associated token
	 */
	requestToken() {
		const token = Math.random();
		this.requesters[token] = false;
		return token;
	}

	/**
	 * Sets the listener's index to true to indicate that they want the request to be canceled.
	 * Once every listener wants the request to be canceled this will call the cancel token cancel
	 * function
	 *
	 * @param {Number} token - The specific requester token that want it to be canceled
	 */
	cancel(token) {
		this.requesters[token] = true;
		if (_.every(this.requesters)) {
			this.source.cancel();
		}
	}
}
