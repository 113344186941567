// Since the webroot is not located at DocumentRoot, there is a problem
// cakePHP has routing AJAX calls, the source code of which contains relative
// cakePHP compliant path to the controller/action. The workaround is
// manually building relative path. Ideally, when the application is in
// production mode and the webroot is a DocumentRoot, the relative path for AJAX
// calls should map correctly.
$.url = function (url) {
	// grabs the content of <base> attribute href and concatenates with
	// function parameter(omitting first character)
	return $('base').attr('href') + url.substr(1);
};

$(document).ready(function () {
	$.ajaxSetup({
		cache: false
	}); // globally disable ajax request caching for IE

	function createDialog(title, msg) {
		const content = $(document.createElement('DIV')).html(msg);
		content.dialog({
			width: 'auto',
			height: 'auto',
			title,
			modal: true,
			close() {
				$(this).remove();
			}
		});
	}

	$(this).ajaxComplete((e, xhr, options) => {
		let errorMsg;
		if (xhr !== undefined) {
			if (xhr.status === 401 || xhr.status === 302) {
				window.location.href = `/login.html?error=errors.expired&success=${encodeURIComponent(window.location)}`;
			} else if (xhr.status === 403) {
				createDialog('Permission Denied', 'You do not have permission to access this page.');
			} else if (xhr.status === 500) {
				if (options.url.indexOf('/lineage') === -1) {
					errorMsg = xhr.getResponseHeader('Warning');
					if (errorMsg !== undefined) {
						createDialog('Error occurred', errorMsg);
					} else {
						createDialog('Error occurred', 'This page is unavailable.');
					}
				}
			} else if (xhr.status === 504) { // 504 gateway timeout
				// noop
			} else if (xhr.status > 500) {
				window.location.href = `/login.html?error=errors.unavailable&success=${encodeURIComponent(window.location)}`;
			}
		}
	});

	$.manageAjax.create('pageChange', {
		queue: false,
		preventDoubbleRequests: true,
		cacheResponse: false,
		abortOld: true,
		cache: false
	});

	/**
	 * Verifies that the front end's user matches what the backend has for the user
	 * Note: This will wait for the current request to finish before scheduling a new request
	 *
	 * @param {Number} timeout - The time between verification requests
	 */
	window.verifyUser = function (timeout) {
		setTimeout(() => {
			$.ajax({
				url: '/lineage/api/my/profile',
				global: false
			}).then((data) => {
				if (parseInt(CURRENT_USER.dbId) === parseInt(data.dbId)) {
					window.verifyUser(timeout);
				} else {
					window.location.reload();
				}
			});
		}, timeout);
	};
});

/*
 * Set the defaults for the validator cross-site. Ignore validation on
 * non-required or hidden fields
 */
$.validator.setDefaults({
	ignore: ':not(.required),:hidden'
});
