/** {number} The time to delay cleaning the store */
export const CLEAN_DELAY = 5000;

export const moduleClean = {
	clean: {
		root: true,
		/**
		 * Starts the process of cleaning any unused items in the cache.
		 * This should only be called by the root vue instance on destroy.
		 * Use $store.commit({moduleName}/removeFromCache, {...}) to remove an individual
		 * item/link from the cache.
		 * This also has a resettable timer so subsequent calls will reset the cleaning delay
		 *
		 *
		 * Unlike the other actions this is called just by doing $store.dispatch('clean') since
		 * this is declared to be callable by the root store only
		 */
		handler(currentContext) {
			if (currentContext.state.cleanTimer) {
				clearTimeout(currentContext.state.cleanTimer);
			}
			currentContext.state.cleanTimer = setTimeout(() => {
				currentContext.state.cleanTimer = undefined;
				currentContext.commit('deepCleanCache');
			}, CLEAN_DELAY);
		}
	}
};

/**
 * Cleans out a given item from the iterableObject that is not used by anything
 * when this method is called
 * Note: Linked items will take multiple iterations to remove since the modules can run in any order
 *
 *
 * @param {Object} apiWatchers - The api watchers to ensure linked items aren't removed
 * @param {object} item - The item to check
 * @param {string} key - The key in the iterable object
 * @param {object} iterableObject - The base object to which the key belongs
 */
export const cleanCacheIterable = function (apiWatchers, item, key, iterableObject) {
	if (item.__ob__ && item.__ob__.dep) {
		// In vue 2.7, the sub list can contain null, so we filter to get an accurate count
		let subCount = 0;
		item.__ob__.dep.subs.some((sub) => {
			if (sub !== null) {
				subCount++;
			}

			// We only care if there are 0 or 1 subs, anything more can be short-circuited
			return subCount > 1;
		});

		if (subCount === 0
			|| (subCount === 1 && apiWatchers !== undefined && apiWatchers[key] !== undefined)
		) {
			Vue.delete(iterableObject, key);
			if (apiWatchers && apiWatchers[key]) {
				apiWatchers[key]();
				Vue.delete(apiWatchers, key);
			}
		}
	}
};
