/**
 * The modulo math operator for a mod b.
 * In javascript a % b is the remainder operator.
 * So.. -3 % 4 is -3, but -3 mod 4 is 1
 *
 * @param {number} a - The first operand
 * @param {number} b - The second operand
 * @return {number} The result
 */
export const mod = function (a, b) {
	return ((a % b) + b) % b;
};

/**
 * @param centerX {Number} The x coordinate of the circle center
 * @param centerY {Number} The y coordinate of the circle center
 * @param radius {Number} The radius of the circle
 * @param angleInDegrees {Number} The angle of the arc
 * @returns {{x: *, y: *}}
 */
export const polarToCartesian = function (centerX, centerY, radius, angleInDegrees) {
	const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;
	return {
		x: centerX + (radius * Math.cos(angleInRadians)),
		y: centerY + (radius * Math.sin(angleInRadians))
	};
};

/**
 * Converts the given angle to the closest 45 degree between 0 and 360
 *
 * @param {number} angle - The angle
 * @return {number} - The closet 45 degree angle
 */
export function calculateAngle(angle) {
	return mod(45 * Math.floor((angle + 22.5) / 45), 360);
}

/**
 *  Converts the given angle to the nearest compass angle (IE: NNE)
 *  @param {number} angle - Angle given
 *  @returns {string} - Closest compass angle name
 */
export function calculateCardinalPoint(angle) {
	angle = mod(angle, 360);
	if (angle > 348.75 || angle < 11.25) {
		return i18n.geoMap.CARDINAL_DIRECTIONS.N;
	}
	if (angle < 33.75) {
		return i18n.geoMap.CARDINAL_DIRECTIONS.NNE;
	}
	if (angle < 56.25) {
		return i18n.geoMap.CARDINAL_DIRECTIONS.NE;
	}
	if (angle < 78.75) {
		return i18n.geoMap.CARDINAL_DIRECTIONS.ENE;
	}
	if (angle < 101.25) {
		return i18n.geoMap.CARDINAL_DIRECTIONS.E;
	}
	if (angle < 123.75) {
		return i18n.geoMap.CARDINAL_DIRECTIONS.ESE;
	}
	if (angle < 146.25) {
		return i18n.geoMap.CARDINAL_DIRECTIONS.SE;
	}
	if (angle < 168.75) {
		return i18n.geoMap.CARDINAL_DIRECTIONS.SSE;
	}
	if (angle < 191.25) {
		return i18n.geoMap.CARDINAL_DIRECTIONS.S;
	}
	if (angle < 213.75) {
		return i18n.geoMap.CARDINAL_DIRECTIONS.SSW;
	}
	if (angle < 236.25) {
		return i18n.geoMap.CARDINAL_DIRECTIONS.SW;
	}
	if (angle < 258.75) {
		return i18n.geoMap.CARDINAL_DIRECTIONS.WSW;
	}
	if (angle < 281.25) {
		return i18n.geoMap.CARDINAL_DIRECTIONS.W;
	}
	if (angle < 303.75) {
		return i18n.geoMap.CARDINAL_DIRECTIONS.WNW;
	}
	if (angle < 326.25) {
		return i18n.geoMap.CARDINAL_DIRECTIONS.NW;
	}
	return i18n.geoMap.CARDINAL_DIRECTIONS.NNW;
}

export default {
	mod,
	polarToCartesian,
	calculateAngle,
	calculateCardinalPoint
};
