import { mod } from '@/utils/math';
import { rgbToHex } from '@/utils/text';

/** {RegExp} Regex for replacing the hsl(*) string */
const HSL_RGX = /^hsl|\(|\)/g;

/**
 * Helper to convert the hue value to a hex value
 *
 * @param {number} p - The p value
 * @param {number} q - The q value
 * @param {number} t - The hue offset value
 * @return {string} The padded hex string (##)
 */
function hueToHex(p, q, t) {
	let hex;
	t = mod(t, 1);

	if (t < 1 / 6) {
		hex = p + (q - p) * 6 * t;
	} else if (t < 1 / 2) {
		hex = q;
	} else if (t < 2 / 3) {
		hex = p + (q - p) * (2 / 3 - t) * 6;
	} else {
		hex = p;
	}
	return Math.round(hex * 255).toString(16).padStart(2, '0');
}

/**
 * Converts an hsl string into an rgb string
 *
 * @param {string} hsl - The hsl string to convert
 * @return {string} The converted rgb string
 */
export const HSLToRGB = function (hsl) {
	const [h1, s1, l1] = hsl.replace(HSL_RGX, '').split(',');
	const h = parseFloat(h1) / 360;
	const s = parseFloat(s1) / 100;
	const l = parseFloat(l1) / 100;

	const q = l < 0.5
		? l * (1 + s)
		: l + s - l * s;
	const p = 2 * l - q;
	const r = s === 0 ? hueToHex(l, 0, 1) : hueToHex(p, q, h + 1 / 3);
	const g = s === 0 ? r : hueToHex(p, q, h);
	const b = s === 0 ? r : hueToHex(p, q, h - 1 / 3);

	return `#${r}${g}${b}`;
};

/**
 * Generates an object containing all the values defined by bootstrap for the default color palette
 * (updates made here and in _bootstrap.less should match each other) The function generates colors
 * by creating a hidden <div>, applying all the defined color classes, and saves the hex values of
 * the colors to the ptcolors object Colors can be accessed anywhere through the ptcolors object.
 * example: ptcolors.primary.color; is the background color for the primary color
 */
export function generateDefinedColors() {
	if (window.ptcolors) {
		return window.ptcolors;
	}
	const ptcolors = {};
	const colors = ['primary', 'secondary', 'success', 'caution', 'warning', 'danger', 'info']; // the color palette must match what is being generated via bootstrap

	colors.forEach((color) => {
		const test = $(`<div id="color-loader" style="display: none;" class="status-${color}-bg"></div>`);
		$(document.body).append(test);
		ptcolors[color] = {};
		ptcolors[color].text = test.css('color');
		ptcolors[color].color = test.css('background-color');
		if (test.css('background-color')) {
			ptcolors[color].hex = rgbToHex(...test.css('background-color').match(/\d+/g).map(Number));
		}

		test.removeClass(`status-${color}-bg`);
		test.addClass(`status-${color}-contrasted-bg`);

		ptcolors[color].contrasted = {};

		ptcolors[color].contrasted.text = test.css('color');
		ptcolors[color].contrasted.color = test.css('background-color');

		test.remove();
	});

	window.ptcolors = ptcolors;
	return ptcolors;
}

/**
 * Takes a color and determines the "best" color to contrast
 *
 * @param {String} color - The RGB color
 * @returns {string} The contrasted color
 */
export function getContrastingColor(color) {
	color = color.length === 7 ? color.substring(1) : color;

	return (parseInt(color.substring(0, 2), 16) * 0.299)
		+ (parseInt(color.substring(2, 4), 16) * 0.587)
		+ (parseInt(color.substring(4, 6), 16) * 0.114) > 186 ? '#000000' : '#FFFFFF';
}
