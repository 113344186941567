/** {array} An empty array so we aren't recreating it */
const EMPTY_ARRAY = [];

/** {string} The separator for an object string */
const SEPARATOR = '.';

/**
 * Gets the last requested key in the provided scope.
 * Note: Only use this if you have long nested objects (e.g. 3+ keys)
 * Examples: { a: { b: [1,2,3] } } -> 'a.b' = [1,2,3], 'a.b.1' = 2, 'a.b.6.b.s' = undefined.
 *
 * @param {object} scope - The scope to look into
 * @param {string} objStr - The dot (.) separated search string
 * @returns {*} The result or undefined if not found
 */
export function getInScope(scope, objStr) {
	const keys = objStr ? objStr.split(SEPARATOR) : EMPTY_ARRAY;
	let i;
	for (i = 0; scope !== undefined && i < keys.length; i++) {
		scope = scope[keys[i]];
	}

	return scope;
}

/**
 * Determines if the object has all of the desired keys set (e.g. not undefined)
 *
 * @param {object} obj - The object to look through
 * @param {string|array} keys - The key to look for, rest args
 * @return {boolean} True if the object has all of the keys, false otherwise
 */
export function hasObjectKeySet(obj, keys) {
	return obj !== undefined && (typeof keys === 'string'
		? obj[keys] !== undefined
		: _.every(keys, (key) => obj[key] !== undefined));
}

/**
 * Converts an object to the api string representation
 * Example: { a: '123', b: 'def'  } -> a:123,b:def
 * Use {@link fromApiString} to convert back to the original object
 *
 * @param {Object|String} obj - The object to convert
 * @return {string} The converted object string
 */
export function toApiString(obj) {
	let result;
	if (obj && typeof obj === 'string') {
		result = obj;
	} else if (obj && Object.keys(obj).length) {
		result = JSON.stringify(obj);
	}
	return result
		? result.replace(/["{}]/g, '')
		: undefined;
}

/**
 * Converts an api string object representation to the object equivalent
 * Example: a:123,b:def -> { a: 123, b: 'def'  }
 * Use {@link toApiString} to the string representation
 *
 * @param {String} str - The api string representation
 * @return {Object} The converted JSON object
 */
export function fromApiString(str) {
	if (str) {
		const pairs = str.split(',');
		const obj = {};
		_.each(pairs, (pair) => {
			const items = pair.split(':');
			obj[items[0]] = items[1];
		});
		return obj;
	}
	return undefined;
}

/**
 * Transforms the string values of an object into their typed equivalent
 * <ul>Examples:
 *  <li>'123' - 123
 *  <li>'true' - true
 * </ul>
 *
 * @param {Object} obj - The object to transform
 * @return {Object} The transformed value object
 */
export function convertStringValues(obj) {
	if (!obj) {
		return undefined;
	}
	const transformed = {};
	_.each(obj, (value, key) => {
		if (value === undefined || value === '') {
			transformed[key] = undefined;
		} else {
			try {
				transformed[key] = JSON.parse(value);
			} catch (e) {
				transformed[key] = value;
			}
		}
	});

	return transformed;
}

/**
 * Transforms the array, or array like, object into a lookup map
 * When using a list of primitives (string, number) do not specify a key
 *
 * @param {*[]} values - The array of values
 * @param {String=} key - The specific key
 * @return {object} The look up map
 */
export function toLookupMap(values, key) {
	const lookup = {};

	if (!values) return lookup;

	if (key) {
		values.forEach((value) => {
			if (value[key] !== undefined) {
				lookup[value[key]] = value;
			}
		});
	} else {
		values.forEach((value) => {
			lookup[value] = true;
		});
	}

	return lookup;
}

/**
 * Abstract Object.create availability in browsers. (Particularly IE8)
 *
 * @param obj
 * @returns
 */
export function clone(obj) {
	if (Object.create) {
		return Object.create(obj);
	}

	function F() {
	}

	F.prototype = obj;
	return new F();
}

/**
 * @typedef {Object} EquivalencyOptions
 * @property {Boolean} [laxNumberTypes] - Whether to use lax equivalency when checking numbers/strings
 * @property {Boolean} [laxBooleanTypes] - Whether to use lax equivalency when checking booleans/strings
 * @property {Boolean} [laxEmptyChecks] - Whether to consider empty strings/arrays as being undefined
 * @property {Boolean} [laxArrayOrder] - Whether to ignore the order of items when determining if arrays are equivalent
 * @property {Object<String, Boolean>} [excludedKeys] - The key paths that should be excluded for objects
 * @property {Object<String, Function>} [customKeys] - The key paths that use a custom check function for objects
 */

const KEY_SEPARATOR = '.';
const ARRAY_IDENTIFIER = 'N';

/**
 * Determines if the two items are equivalent.
 *
 * @param {*} itemA - The first item
 * @param {*} itemB - The second item
 * @param {EquivalencyOptions} options - The options to aid in finding if the items are equivalent
 * @param {String} keyPath - The items key path. This is used internally for nested checks
 * @returns {boolean} True if the items are equivalent, false otherwise
 */
export function isEquivalent(itemA, itemB, options = {}, keyPath = '') {
	if (window.DEBUG_EQUIVALENT) {
		const spacing = '   '.repeat(keyPath ? keyPath.split('.').length : 0);
		// eslint-disable-next-line no-console
		console.log(`${spacing} - `, keyPath || '', itemA, itemB);
	}
	let result;
	if (itemA === itemB) {
		result = true;
	} else {
		const itemClass = Object.prototype.toString.call(itemA);
		const otherClass = Object.prototype.toString.call(itemB);
		if (otherClass !== itemClass) {
			if (options.laxEmptyChecks
				&& ((itemClass === '[object String]' && otherClass === '[object Undefined]')
					|| (itemClass === '[object Undefined]' && otherClass === '[object String]')
					|| (itemClass === '[object Array]' && otherClass === '[object Undefined]')
					|| (itemClass === '[object Undefined]' && otherClass === '[object Array]'))
			) {
				result = itemA === undefined ? itemB.length === 0 : itemA.length === 0;
			} else if (options.laxNumberTypes
				&& ((itemClass === '[object String]' && otherClass === '[object Number]')
					|| (itemClass === '[object Number]' && otherClass === '[object String]'))
			) {
				result = itemA.toString() === itemB.toString();
			} else if (options.laxBooleanTypes
				&& ((itemClass === '[object String]' && otherClass === '[object Boolean]')
					|| (itemClass === '[object Boolean]' && otherClass === '[object String]'))
			) {
				result = itemA.toString() === itemB.toString();
			} else if (options.laxBooleanTypes
				&& ((itemClass === '[object Undefined]' && otherClass === '[object Boolean]')
					|| (itemClass === '[object Boolean]' && otherClass === '[object Undefined]'))
			) {
				result = !itemA === !itemB;
			} else {
				result = false;
			}
		} else if (itemClass === '[object String]' || itemClass === '[object Boolean]') {
			result = false;
		} else if (itemClass === '[object RegExp]' || itemClass === '[object Function]') {
			result = itemA.toString() === itemB.toString();
		} else if (itemClass === '[object Number]') {
			result = Number.isNaN(itemA) && Number.isNaN(itemB);
		} else if (itemClass === '[object Date]') {
			result = +itemA === +itemB;
		} else if (itemClass === '[object Object]') {
			result = Object.keys({
				...itemA,
				...itemB
			}).every((key) => {
				const path = keyPath + key;
				if (options.excludedKeys && options.excludedKeys[path]) {
					return true;
				}
				if (options.customKeys && options.customKeys[path]) {
					return options.customKeys[path](itemA[key], itemB[key]);
				}
				return isEquivalent(itemA[key], itemB[key], options, path + KEY_SEPARATOR);
			});
		} else if (itemClass === '[object Array]') {
			const bCopy = options.laxArrayOrder ? itemB.slice() : undefined;
			const path = keyPath + ARRAY_IDENTIFIER;
			result = itemA.length === itemB.length
				&& ((options.excludedKeys && options.excludedKeys[path])
					|| itemA.every((value, index) => {
						const checker = options.customKeys ? options.customKeys[path] : undefined;
						if (options.laxArrayOrder) {
							// Remove duplicates so we ensure there is a matching equivalent for every item
							const foundIndex = bCopy.findIndex(
								(other) => (checker ? checker(value, other) : isEquivalent(value, other, options, path + KEY_SEPARATOR))
							);
							if (foundIndex > -1) {
								bCopy.splice(foundIndex, 1);
							}
							return foundIndex > -1;
						}
						return checker ? checker(value, itemB[index]) : isEquivalent(value, itemB[index], options, path + KEY_SEPARATOR);
					}));
		} else {
			// Fallback message in-case we checked something unexpected
			// eslint-disable-next-line no-console
			console.warn(`No checker for item: ${itemClass}`, itemA, itemB);
			result = false;
		}
	}

	if (window.DEBUG_EQUIVALENT) {
		const spacing = '   '.repeat(keyPath ? keyPath.split('.').length : 0);
		// eslint-disable-next-line no-console
		console.log(`${spacing} - `, keyPath || '', ' = ', result);
	}
	return result;
}

/** @type {EquivalencyOptions} The options to determine if a time range is equivalent */
const TIME_RANGE_EQUIVALENT_OPTIONS = {
	laxNumberTypes: true,
	laxBooleanTypes: true,
	excludedKeys: {
		timeZoneId: true
	}
};

/**
 * Determines if the given time ranges are the same
 *
 * @param {String|Object} rangeA - The first time range
 * @param {String|Object} rangeB - The second time range
 * @returns {Boolean} True if the ranges are equivalent, false otherwise
 */
export function isSameTimeRange(rangeA, rangeB) {
	if (rangeA === rangeB) return true;
	if ((rangeA && !rangeB) || (!rangeA && rangeB)) return false;

	if (typeof rangeA === 'string') {
		rangeA = rangeA[0] === '{'
			? JSON.parse(rangeA)
			: fromApiString(rangeA);
	}
	if (typeof rangeB === 'string') {
		rangeB = rangeB[0] === '{'
			? JSON.parse(rangeB)
			: fromApiString(rangeB);
	}

	return isEquivalent(rangeA, rangeB, TIME_RANGE_EQUIVALENT_OPTIONS);
}

/**
 * Generates an object that contains the differences between A (base) when compared to B
 *
 * @param {Object} objectA - The base object to use
 * @param {Object} objectB - The object to compare to
 * @returns {{}|undefined} The key/value differences of the base
 */
export function getBaseDifference(objectA, objectB) {
	if (objectA === objectB) return undefined;
	if (objectA === undefined || objectB === undefined) return objectA;

	let hasDifference = false;
	const differences = {};
	Object.keys(objectA).forEach((key) => {
		if (!isEquivalent(objectA[key], objectB[key])) {
			differences[key] = objectA[key];
			hasDifference = true;
		}
	});

	return hasDifference ? differences : undefined;
}
