import { subscribe } from '@/store/synced-subscriptions';
import { hasObjectKeySet, getInScope, isEquivalent } from '@/utils/object';
import Vue from 'vue';

/**
 * Global mixin declaration for pt-store-computed.
 * Allows for more declarative syntax when defining properties dependent our custom store modules
 *
 * Note: derived means the path to the data.
 * 	derivedKey: 'some.object.path' is transformed to context['some']['object']['path'] for the key
 *
 * <ul>All properties:
 *  <li>{string} module/derivedModule - The store module to get the data from
 *  <li>{string} url/derivedUrl - The url to use with the module to hit the api with
 *  <li>{string} key/derivedKey - The key to store the result from the api and the key to get the
 *  		data from the store as
 *  <li>{object|string} params/derivedParams - The parameters for the api call
 *  <li>{string|number} customerId/derivedCustomerId - Whether or not to use the customer specific
 *  		endpoint (e.g. api/customers/{id}/endPoint
 *  <li>{string} urlKey/derivedUrlKey - Helper for declaring url + key as the same value
 *  <li>{string} injectedUrl/derivedInjectedUrl - The url to inject inside the base url
 *  <li>{String} replaceUrl/derivedReplaceUrl - The url to replace the base url
 *  <li>{string} loadingIndicator - The name of the loading indicator data value
 *  		(Does not need to be declared by the component)
 *  <li>{string} fetch - The strategy for fetching an item from the api
 *  	<ul>Values:
 *  		<li>AS_NEEDED (default) - Returns the keyed item form the cache
 *  		<li>AS_PRIVATE - Creates a private instance of the item in the cache
 *  	</ul>
 *  <li>{string} refresh - When to refresh (re-hit) the api when populating the data
 *  	<ul>Values:
 *  		<li>NEVER (default) - Never call set if the cache contains the keyed item
 *  		<li>IMMEDIATE - Ignores the cached value for the first call only.
 *  				(Makes sure the keyed item is up-to-date)
 *  		<li>ON_MODULE_UPDATE - Calls set if the module's create/update/delete actions were called
 *  				(only after action finishes successfully)
 *  		<li>ALWAYS - Whenever the set parameters changed
 *  	</ul>
 *  <li>{string} derivedData - The data to use from the store. Useful if the component only needs a
 * 		single piece of information for the store's data (e.g. an actor's actor class id)
 * 	<li>{string|array<string>} refreshOnActions - The module actions to refresh when they update
 * 	<li>{boolean} getOnly - Disable all set/watch functionality. Used when using an api based key
 * 	<li>{boolean} pubsub - Enables pub sub to re-fetch the data on an interval
 * 	<li>{string} pubsubCategory - The category for synchronizing pubsub requests
 * 	<li>{boolean} useMy - Uses the user specific endpoint (/api/my/...)
 * </ul>
 * Most common usage:
 * actor: {
 * 		module: 'the module', // 'actors', 'jobs', etc.
 *		derivedUrlKey: 'the property' // 'actorId', 'jobId', etc.
 * }
*/
// Helpful constants
const GET = '/get';
const GET_LINKS = '/getLinks';
const GET_CANCEL_TOKEN = '/getCancelToken';
const CANCEL_REQUEST = '/cancelRequest';
const KEY = '__key';
const URL = '__url';
const MODULE = '__module';
const SET = '__set';
const PARAMS = '__params';
const CUSTOMER_ID = '__customer_id';
const INJECTED_URL = '__injected_url';
const REPLACE_URL = '__replace_url';
const ERROR = '__error';
const FETCHED = '__fetched';
const HEADERS = '__headers';
const DATA = '__data';
const READY = '__ready';
const REQUEST_TIME = '__request_time';
const SERVER_TIME_HEADER = 'pt-server-time';
const LAST_FETCH_ARGS = '__last_fetch_args';
const VALID_GET = '__is_valid_for_get';
const VALID_FETCH = '__is_valid_for_fetch';
const ABORT_TOKEN = '__request_abort_index';
const FETCHES = {
	AS_PRIVATE: 'AS_PRIVATE',
	AS_NEEDED: 'AS_NEEDED' // default
};
const REFRESHES = {
	NEVER: 'NEVER', // default
	IMMEDIATE: 'IMMEDIATE',
	ON_MODULE_UPDATE: 'ON_MODULE_UPDATE',
	ALWAYS: 'ALWAYS'
};
const NOT_READY = new Error('Not Ready');

/** {array<string>} The needed keys for pubsub to run as a list */
const PUB_SUB_LIST_KEYS = ['offset', 'limit'];

/**
 *Handler for getting the pub sub data to determine if the pub sub fetch returned anything
 *
 * @param {*} data - The response from the pub sub request
 * @returns {*} Truthy if the fetch returned data, falsy otherwise
 */
function PUB_SUB_DATA(data) {
	return data && data.length;
}

/**
 * Generates the individual get from store method.
 * Note: this will call the setter if it hasn't been called yet
 *
 * @param {object} options - The mixin options for a specified key
 * @param {string} baseKey - The base parameter key
 * @returns {Function} The store specific get function
 */
function getItemFromStore(options, baseKey) {
	return function () {
		let data;
		if (!this[baseKey + VALID_GET]) {
			return undefined;
		}
		if ((options.refresh !== REFRESHES.IMMEDIATE && options.refresh !== REFRESHES.ALWAYS)
			|| this[baseKey + FETCHED]
		) {
			data = this.$store.getters[this[baseKey + MODULE] + GET]({
				key: this[baseKey + KEY],
				private: options.fetch === FETCHES.AS_PRIVATE
			});
		}

		// Calls the setter if it hasn't already been called
		if (data === undefined && !this[baseKey + FETCHED] && this[baseKey + SET]) {
			_.defer(this[baseKey + SET]);
		}
		return data;
	};
}

/**
 * Sets a new computed property for the given option
 *
 * @param {object} computed - The computed properties of the vue instance
 * @param {string} baseKey - The name to set the new computed property as
 * @param {any} option - The constant option to use if not deriving the value
 * @param {string} derivedOption - The vue instance path to the option value
 */
function generateDynamicOptionGetter(computed, baseKey, option, derivedOption) {
	if (option || derivedOption) {
		computed[baseKey] = function () {
			return derivedOption
				? getInScope(this, derivedOption)
				: option;
		};
	}
}

/**
 * Generates the computed getters for all options + their derived counter parts
 *
 * @param {object} computed - The vue instance's computed properties
 * @param {options} options - The store computed data options
 * @param {string} baseKey - The store computed data key
 */
function generateOptionGetters(computed, options, baseKey) {
	if (options.fetch !== FETCHES.AS_PRIVATE) {
		computed[baseKey] = function () {
			this[baseKey + READY] = true;
			return options.derivedData
				? getInScope(this[baseKey + DATA], options.derivedData)
				: this[baseKey + DATA];
		};
	}

	generateDynamicOptionGetter(computed, baseKey + URL, options.url, options.derivedUrl);
	generateDynamicOptionGetter(computed, baseKey + KEY, options.key, options.derivedKey);
	generateDynamicOptionGetter(computed, baseKey + MODULE, options.module, options.derivedModule);
	generateDynamicOptionGetter(computed, baseKey + PARAMS, options.params, options.derivedParams);
	generateDynamicOptionGetter(
		computed,
		baseKey + CUSTOMER_ID,
		options.customerId,
		options.derivedCustomerId
	);
	generateDynamicOptionGetter(
		computed,
		baseKey + INJECTED_URL,
		options.injectedUrl,
		options.derivedInjectedUrl
	);
	generateDynamicOptionGetter(
		computed,
		baseKey + REPLACE_URL,
		options.replaceUrl,
		options.derivedReplaceUrl
	);

	computed[baseKey + VALID_GET] = function () {
		return this[baseKey + READY]
			&& this[baseKey + MODULE] !== undefined
			&& this[baseKey + KEY] !== undefined;
	};

	const hasUrlSet = (options.url || options.derivedUrl) !== undefined;
	const hasKeySet = (options.key || options.derivedKey) !== undefined;
	const hasParamsSet = (options.params || options.derivedParams) !== undefined;
	const hasCustomerIdSet = (options.customerId || options.derivedCustomerId) !== undefined;
	const hasInjectedUrlSet = (options.injectedUrl || options.derivedInjectedUrl) !== undefined;
	const hasReplaceUrlSet = (options.replaceUrl || options.derivedReplaceUrl) !== undefined;

	computed[baseKey + VALID_FETCH] = function () {
		return this[baseKey + READY]
			&& this[baseKey + MODULE]
			&& (!hasUrlSet || this[baseKey + URL] !== undefined)
			&& (!hasKeySet || this[baseKey + KEY] !== undefined)
			&& (!hasParamsSet || this[baseKey + PARAMS] !== undefined)
			&& (!hasCustomerIdSet || this[baseKey + CUSTOMER_ID] !== undefined)
			&& (!hasInjectedUrlSet || this[baseKey + INJECTED_URL] !== undefined)
			&& (!hasReplaceUrlSet || this[baseKey + REPLACE_URL] !== undefined);
	};
}

/**
 * Generates the private single time setter to populate the desired information.
 * This will unwatch the corresponding module, url, key, and params.
 *
 * @param {function} setterFunction - The standard setter function
 * @param {object} options - The data options
 * @param {string} baseKey - The base data key
 * @returns {Function} The generated private setter function
 */
function generatePrivateSetter(setterFunction, options, baseKey) {
	return function () {
		const that = this;
		setterFunction.call(this).then(() => {
			const result = getItemFromStore(options, baseKey).call(that);
			if (result) {
				that[baseKey] = result;
			} else if (options.default) {
				that[baseKey] = typeof options.default === 'string'
					? that[options.default]()
					: options.default();
			}
		}, (error) => {
			if (error === NOT_READY && options.default) {
				that[baseKey] = typeof options.default === 'string'
					? that[options.default]()
					: options.default();
			} else {
				throw error;
			}
		}).finally(() => {
			if (that[baseKey]) {
				_.each(that.__storeComputedWatchers[baseKey], (unwatch) => {
					unwatch();
				});
				delete that.__storeComputedWatchers[baseKey];
			}
		});
	};
}

/**
 * Generates the standard store populator function to call the store's dispatch function when
 * all information is set
 *
 * @param {string} baseKey - The base data key
 * @param {string} loadingIndicator - The name of the loading indicator
 * @param {REFRESHES} refresh - The refresh strategy
 * @param {boolean} fetchLinks - Whether or not to fetch the configured module's links
 * @param {boolean} isMap - Whether or not the fetched data is a map of items
 * @param {boolean} useMy - Whether or not to ues the user specific api endpoint (/api/my...)
 * @param {FETCHES} fetch - The fetching strategy
 * @returns {Function} The setter function that, when called, will return the state of the
 * 		dispatch call, or a rejected promise if still waiting for information.
 * <ul>Accepted options (for internal use only for override-ability):
 *  <li>{boolean} ignore - Whether or not to force a re-fetch
 * </ul>
 */
function generateStorePopulator(
	baseKey,
	loadingIndicator,
	refresh,
	fetchLinks,
	isMap,
	useMy,
	fetch
) {
	return function (options) {
		const wasFetched = this[baseKey + FETCHED];
		if (this[baseKey + VALID_FETCH]) {
			if (loadingIndicator) {
				this[loadingIndicator] = true;
			}

			const module = this[baseKey + MODULE];

			const fetchArguments = {
				key: this[baseKey + URL],
				linkedKey: this[baseKey + KEY],
				params: this[baseKey + PARAMS],
				ignore: (!wasFetched && refresh === REFRESHES.IMMEDIATE)
					|| (options && options.ignore)
					|| refresh === REFRESHES.ALWAYS,
				customerId: this[baseKey + CUSTOMER_ID],
				injectedUrl: this[baseKey + INJECTED_URL],
				replaceUrl: this[baseKey + REPLACE_URL],
				fetchLinks,
				isMap,
				useMy,
				namedModule: module
			};

			// Fetch args are only set while the request is running
			if (this[baseKey + LAST_FETCH_ARGS]) {
				if (isEquivalent(fetchArguments, this[baseKey + LAST_FETCH_ARGS], { excludedKeys: { ignore: true } })) {
					return Promise.resolve();
				}

				this.abortRequest(baseKey);
			}

			this[baseKey + LAST_FETCH_ARGS] = fetchArguments;
			const request = this.$store.dispatch(
				module + GET,
				fetchArguments
			).then((response) => {
				if (response && response.headers) {
					this[baseKey + HEADERS] = response.headers;
					this[baseKey + REQUEST_TIME] = response.headers[SERVER_TIME_HEADER];
				}
			}, (error) => {
				this[baseKey + ERROR] = error;
			}).finally(() => {
				if (this[baseKey + LAST_FETCH_ARGS] === fetchArguments) {
					if (loadingIndicator) {
						this[loadingIndicator] = false;
					}

					this[baseKey + LAST_FETCH_ARGS] = undefined;
					this[baseKey + ABORT_TOKEN] = undefined;
					this[baseKey + FETCHED] = true;
				}
			});

			if (this.$store.getters[module + GET_CANCEL_TOKEN]) {
				this[baseKey + ABORT_TOKEN] = this.$store.getters[module + GET_CANCEL_TOKEN](
					fetchArguments
				);
			}

			return request;
		}
		return fetch === FETCHES.AS_PRIVATE ? Promise.reject(NOT_READY) : Promise.resolve();
	};
}

/**
 * Sets the set method for populating the store
 *
 * @param {object} methods - The vue instance method properties
 * @param {object} options - The configured options for the base key
 * @param {string} baseKey - the base key
 */
function generateStoreSetter(methods, options, baseKey) {
	const storePopulator = generateStorePopulator(
		baseKey,
		options.loadingIndicator,
		options.refresh,
		options.fetchLinks,
		options.isMap,
		options.useMy,
		options.fetch
	);

	methods[baseKey + SET] = options.fetch === FETCHES.AS_PRIVATE
		? generatePrivateSetter(storePopulator, options, baseKey)
		: storePopulator;
}

/**
 * Generates the necessary data override function to injected our own data properties.
 * Adding items to the data object allows them to be watched and have dynamic updaters available
 *
 * @param {object} data - The storeComputed data object
 * @param {object} options - The configured options for the base key
 * @param {string} baseKey - The base data key
 */
function extendOptionData(data, options, baseKey) {
	if (options.loadingIndicator) {
		data[options.loadingIndicator] = false;
	}
	if (options.fetch === FETCHES.AS_PRIVATE) {
		data[baseKey] = undefined;
	} else {
		data[baseKey + DATA] = undefined;
	}
	data[baseKey + FETCHED] = false;
	data[baseKey + READY] = false;
	data[baseKey + HEADERS] = undefined;
	data[baseKey + ERROR] = undefined;
}

/**
 * Converts the list of modules to a regexp to evaluate the store action's type
 *
 * @param {string|array<string>} modules - The list of modules
 * @return {RegExp} The converted module actions
 */
export function toActionTypeRegex(modules) {
	const str = [];
	modules = _.isArray(modules) ? modules : [modules];
	_.each(modules, (module) => {
		str.push(`^${module}/`);
	});

	return new RegExp(`(${str.join('|')})(?!get|cancelRequest)`);
}

/**
 * Initializes a pub sub interval for automatically updating the stored value
 *
 * @param {object} context - The context of the vue instance
 * @param {string} baseKey - The key of the mixin option
 * @param {object} options - The store computed property options
 * @return {function} The pub sub destroyer
 */
function initializePubSub(context, baseKey, options) {
	return subscribe(() => {
		// Pre-check to make sure everything is valid before calling the dispatch function
		if (context[baseKey + VALID_FETCH] && context[baseKey + REQUEST_TIME]) {
			if (context[baseKey + PARAMS]
				&& hasObjectKeySet(context[baseKey + PARAMS], PUB_SUB_LIST_KEYS)
			) {
				// Fetch a single item based on modified since
				const key = `${context[baseKey + KEY]}.pubsub`;
				const module = context[baseKey + MODULE] + GET;
				return context.$store.dispatch(module, {
					key: context[baseKey + URL],
					linkedKey: key,
					params: {
						...context[baseKey + PARAMS],
						limit: 1,
						offset: 0,
						modifiedSince: context[baseKey + REQUEST_TIME],
						returnTotalCount: options.returnTotalCountOnPubsub
					},
					ignore: true,
					customerId: context[baseKey + CUSTOMER_ID],
					injectedUrl: context[baseKey + INJECTED_URL],
					replaceUrl: context[baseKey + REPLACE_URL],
					isMap: options.isMap,
					useMy: options.useMy
				}).then(() => {
					// Verify that there is at least 1 updated item returned
					const result = context.$store.getters[module]({ key });
					if ((options.pubsubData || PUB_SUB_DATA)(result)) {
						return context[baseKey + SET]({ ignore: true });
					}
					return Promise.resolve();
				});
			}
			return context[baseKey + SET]({ ignore: true });
		}
		return Promise.resolve();
	}, options.pubsubCategory);
}

/**
 * Generates the watchers for key, module, url, and params
 * Note: This must be called after creation so we can use $watch
 *
 * @param {object} context - The context of the vue instance
 * @param {object} options - The mixin option for a piece of data
 * @param {string} baseKey - The key of the mixin option
 * @returns {array<function>} The list of unwatch functions
 */
function initializeWatcherOptions(context, options, baseKey) {
	const setHandler = context[baseKey + SET];
	const watchers = [];
	let actionRgx;

	if (options.fetch === FETCHES.AS_PRIVATE) {
		context[baseKey + READY] = true;
		context[baseKey + SET]();
	} else {
		watchers.push(context.$watch(getItemFromStore(options, baseKey), function (data) {
			if (data !== undefined) {
				this[baseKey + FETCHED] = true;
			}

			if (this[baseKey + DATA] !== data) {
				this[baseKey + DATA] = data;
			}
		}, { immediate: true }));
	}

	if (options.getOnly) {
		return watchers;
	}

	if (options.key || options.derivedKey) {
		watchers.push(context.$watch(baseKey + KEY, setHandler));
	}
	if (options.module || options.derivedModule) {
		watchers.push(context.$watch(baseKey + MODULE, setHandler));
	}
	if (options.url || options.derivedUrl) {
		watchers.push(context.$watch(baseKey + URL, setHandler));
	}
	if (options.params || options.derivedParams) {
		watchers.push(context.$watch(baseKey + PARAMS, setHandler));
	}
	if (options.customerId || options.derivedCustomerId) {
		watchers.push(context.$watch(baseKey + CUSTOMER_ID, setHandler));
	}
	if (options.injectedUrl || options.derivedInjectedUrl) {
		watchers.push(context.$watch(baseKey + INJECTED_URL, setHandler));
	}
	if (options.replaceUrl || options.derivedReplaceUrl) {
		watchers.push(context.$watch(baseKey + REPLACE_URL, setHandler));
	}
	if (options.refresh === REFRESHES.ON_MODULE_UPDATE) {
		watchers.push(context.$store.subscribeAction({
			after(action) {
				if (action.type.startsWith(context[baseKey + MODULE])
					&& action.type !== context[baseKey + MODULE] + GET
					&& action.type !== context[baseKey + MODULE] + GET_LINKS
				) {
					context[baseKey + SET]({ ignore: true });
				}
			}
		}));
	}
	if (options.refreshOnActions) {
		actionRgx = toActionTypeRegex(options.refreshOnActions);
		watchers.push(context.$store.subscribeAction({
			after(action) {
				if (actionRgx.test(action.type)) {
					context[baseKey + SET]({ ignore: true });
				}
			}
		}));
	}
	if (options.pubsub && window.SUBSCRIPTION_INTERVAL > 0) {
		watchers.push(initializePubSub(context, baseKey, options));
	}
	return watchers;
}

Vue.config.optionMergeStrategies.storeComputed = Vue.config.optionMergeStrategies.computed;
const ptStoreComputed = {
	methods: {
		/**
		 * Aborts the specified keyed request
		 *
		 * @param {String} baseKey - The key to abort
		 */
		abortRequest(baseKey) {
			const savedArguments = this[baseKey + LAST_FETCH_ARGS];
			const abortToken = this[baseKey + ABORT_TOKEN];
			if (abortToken && savedArguments) {
				this.$store.dispatch(savedArguments.namedModule + CANCEL_REQUEST, {
					options: savedArguments,
					token: abortToken
				});
			}
		}
	},
	/**
	 * Called before the mixin has been created
	 * Initializes all storeComputed properties
	 */
	beforeCreate() {
		const that = this;
		const data = {};
		if (!this.$options || _.isEmpty(this.$options.storeComputed)) {
			return;
		}

		this.$options.computed = this.$options.computed || {};
		this.$options.methods = this.$options.methods || {};
		const existingDataFunction = this.$options.data;

		_.each(this.$options.storeComputed, (options, baseKey) => {
			// Normalize and set the default options here
			if (options.urlKey) {
				options.url = options.urlKey;
				options.key = options.urlKey;
			} else if (options.derivedUrlKey) {
				options.derivedUrl = options.derivedUrlKey;
				options.derivedKey = options.derivedUrlKey;
			}
			if (!options.fetch) {
				options.fetch = FETCHES.AS_NEEDED;
			}
			if (!options.refresh) {
				options.refresh = REFRESHES.NEVER;
			}
			if (options.pubsub && !options.returnTotalCountOnPubsub) {
				options.returnTotalCountOnPubsub = false;
			}

			generateOptionGetters(that.$options.computed, options, baseKey);
			if (!options.getOnly) {
				generateStoreSetter(that.$options.methods, options, baseKey);
			}
			extendOptionData(data, options, baseKey);
		});

		this.$options.data = function (...args) {
			return existingDataFunction
				? _.extend(existingDataFunction.apply(this, args), data)
				: data;
		};
	},

	/**
	 * Called when the mixin has been created.
	 * Initializes the computed watchers
	 * stores the unwatch function so we can unwatch private setter states
	 */
	created() {
		const that = this;
		if (!this.$options || _.isEmpty(this.$options.storeComputed)) {
			return;
		}
		this.__storeComputedWatchers = _.mapObject(
			this.$options.storeComputed,
			(options, key) => initializeWatcherOptions(that, options, key)
		);
	},

	/**
	 * Called when the mixin is starting to be destroyed
	 * Unwatches all watchers
	 */
	beforeDestroy() {
		if (!this.$options || _.isEmpty(this.$options.storeComputed)) {
			return;
		}
		_.each(this.__storeComputedWatchers, (unwatchers, baseKey) => {
			_.each(unwatchers, (unwatch) => {
				unwatch();
			});
			this.abortRequest(baseKey);
		});
	}
};
Vue.mixin(ptStoreComputed);

export default ptStoreComputed;
