import createAxiosInstance from '@/store/helpers/create-axios-instance';
import { moduleClean, cleanCacheIterable } from '@/store/helpers/store-clean';

/** {RegExp} The standard name value pair delimiter */
const NVP_SEPARATOR = /\|:\|/;

/** {number} The number of milliseconds to delay requesting current readings */
const REQUEST_DELAY = 50;

/** {object} The currently pending requests */
const pendingRequests = {};

/** {object} The currently fetching requests */
const sentRequests = {};

/**
 * Generates the standard getters for getting items from the cache
 * <ul>This provides the following getters
 *	<li>get - All interactions with the cache use get
 *	<li>getAxios - Specific to allow outside instances to use the created axios instance
 * </ul>
 *
 * @param {axios} axiosInstance - The axios instance that the module is using
 * @return {object} The generated getters
 */
function generateModuleGetters(axiosInstance) {
	return {
		/**
		 * Gets the specific item from the cache.
		 *
		 * @param {object} currentState - The current state of the module
		 * @return {function} The cache accessor function
		 * <ul>parameters
		 *	<li>{number|array} ids - The asset ids to populate.
		 *  <li>{string} requestCategory - The request category
		 * </ul>
		 */
		get(currentState) {
			return function (parameters) {
				const categoryCache = currentState.requestCategories[parameters.requestCategory];
				let result;
				if (categoryCache) {
					if (_.isArray(parameters.ids)) {
						result = {};
						_.each(parameters.ids, (id) => {
							if (categoryCache[id]) {
								result[id] = categoryCache[id];
							}
						});
					} else {
						result = categoryCache[parameters.ids];
					}
				}
				return _.isEmpty(result) ? undefined : result;
			};
		},

		/**
		 * @return {axios} The created axios instance
		 */
		getAxios() {
			return axiosInstance;
		}
	};
}

/**
 * Adds the raw reading to the module's cache
 *
 * @param {object} state - The current state of the module
 * @param {object} reading - The raw reading to add
 * @param {string|number} id - The id of the asset the reading belongs to
 * @param {string} requestCategory - The request category the reading went with
 */
function addReadingToCache(state, reading, id, requestCategory) {
	const split = reading.name.split(NVP_SEPARATOR);
	const name = split[0];
	const key = `${id}-${name}`;
	const parsedReading = _.defaults({
		name,
		label: split[1],
		abbreviation: split[2]
	}, reading);

	if (!_.isEqual(parsedReading, state.readings[key])) {
		Vue.set(state.readings, key, parsedReading);
	}

	// Build up the dynamic getter and references
	if (requestCategory) {
		if (!state.requestCategories[requestCategory]) {
			Vue.set(state.requestCategories, requestCategory, {});
		}
		if (!state.requestCategories[requestCategory][id]) {
			Vue.set(state.requestCategories[requestCategory], id, {});
		}
		if (!state.requestCategories[requestCategory][id][name]) {
			Object.defineProperty(state.requestCategories[requestCategory][id], name, {
				get() {
					return state.readings[key];
				},
				enumerable: true
			});
		}
	}
}

/**
 * Generates the standard mutations for interacting with the reading cache
 * <ul>This provides the following mutations:
 *	<li>addToCache
 *	<li>deepCleanCache
 * </ul>
 *
 * @return {object} The standard module mutations
 */
function generateModuleMutations() {
	return {
		/**
		 * Adds all current reading bundles to the cache
		 *
		 * @param {object} state - The current state of the module
		 * @param {object} payload - The mutation's payload
		 * <ul>Consists of:
		 *  <li>{object|array} bundles - The list of reading bundles
		 *  <li>{string} request
		 */
		addToCache(state, payload) {
			const bundles = _.isArray(payload.bundles) ? payload.bundles : [payload.bundles];
			const category = payload.requestCategory;

			// Iterate over all bundles and readings in those bundles to add them to the cache
			_.each(bundles, (bundle) => {
				if (bundle.readingList && !_.isEmpty(bundle.readingList.nameValuePairs)) {
					_.each(bundle.readingList.nameValuePairs, (reading) => {
						addReadingToCache(state, reading, bundle.sensorName, category);
					});
				} else if (category) {
					// Ensure an empty request category object is generated to prevent multiple
					// requests for readings on something that doesn't have any readings
					if (!state.requestCategories[category]) {
						Vue.set(state.requestCategories, category, {});
					}
					if (!state.requestCategories[category][bundle.sensorName]) {
						Vue.set(state.requestCategories[category], bundle.sensorName, {});
					}
				}
			});
		},

		/**
		 * Removes all currently unused readings from the cache.
		 * This should only be called by using $store.dispatch('clean') or in a unit test
		 * <ul>This will:
		 *	<li>Remove any unused asset specific request category
		 *	<li>Remove any unused cached readings that aren't used by a request category
		 * </ul>
		 */
		deepCleanCache(currentState) {
			const cleanFunction = cleanCacheIterable.bind(undefined, undefined);
			_.each(currentState.requestCategories, (requestCategory, key, all) => {
				_.each(requestCategory, cleanFunction);

				// Clean up empty request categories
				if (_.isEmpty(requestCategory)) {
					Vue.delete(all, key);
				}
			});
			_.each(currentState.readings, cleanFunction);
		}
	};
}

/**
 * Removes any existing request category + ids combination from the given id set
 *
 * @param {object} currentContext - The current modules context
 * @param {array<Number>} ids - The ids to request
 * @param {string} requestCategory - The request category of interest
 * @returns {array} The ids that need to be requested
 */
function removeExistingRequested(currentContext, ids, requestCategory) {
	const remaining = [];
	_.each(ids, (id) => {
		id = parseInt(id);
		if ((!currentContext.state.requestCategories[requestCategory]
				|| !currentContext.state.requestCategories[requestCategory][id])
			&& (!sentRequests[requestCategory]
				|| !sentRequests[requestCategory].length
				|| !sentRequests[requestCategory].find((request) => request.ids.indexOf(id) !== -1))
			&& (!pendingRequests[requestCategory]
				|| _.indexOf(pendingRequests[requestCategory].ids, id) === -1)
		) {
			remaining.push(id);
		}
	});
	return remaining;
}

/**
 * Fetches the current readings for the ids that aren't currently cached
 *
 * @param {object} currentContext - The current modules context
 * @param {axios} axiosInstance - The generated axios instance
 * @param {array<Number>} ids - The list of ids to use
 * @param {string} requestCategory - The request category of interest
 * @returns {Promise} Resolved when all ids are populated
 */
function addMissingReadings(currentContext, axiosInstance, ids, requestCategory) {
	ids = removeExistingRequested(currentContext, ids, requestCategory);
	if (_.isEmpty(ids)) {
		// All ids have already been requested
		return Promise.resolve();
	}

	if (pendingRequests[requestCategory]) {
		pendingRequests[requestCategory].ids.push(...ids);
	} else {
		pendingRequests[requestCategory] = {
			ids,
			promise: new Promise(((resolve) => {
				// Delay the request so we can batch similar request categories
				setTimeout(() => {
					const currentRequest = pendingRequests[requestCategory];
					if (sentRequests[requestCategory]) {
						sentRequests[requestCategory].push(currentRequest);
					} else {
						sentRequests[requestCategory] = [currentRequest];
					}
					delete pendingRequests[requestCategory];

					axiosInstance.get(undefined, {
						params: {
							dbids: currentRequest.ids.join(','),
							returnUiName: true,
							attributeFilter: requestCategory,
							deriveAddress: requestCategory === 'LOCATION' || requestCategory === 'LOCATION_HISTORY',
							originalActor: true
						}
					}).then((response) => {
						currentContext.commit('addToCache', {
							bundles: response.data,
							requestCategory
						});
					}).finally(() => {
						if (sentRequests[requestCategory].length === 1) {
							delete sentRequests[requestCategory];
						} else {
							sentRequests[requestCategory].splice(
								sentRequests[requestCategory].indexOf(currentRequest),
								1
							);
						}
						resolve();
					});
				}, REQUEST_DELAY);
			}))
		};
	}
	return pendingRequests[requestCategory].promise;
}

/**
 * Fetches the current readings with parent ids or sub assets.
 * Note: If there already is a matching request
 *
 * @param {object} currentContext - The current modules context
 * @param {axios} axiosInstance - The generated axios instance
 * @param {object} options - The request options
 * <ul>Consists of:
 *  <li>{array} ids - The asset ids
 *  <li>{string} requestCategory - The request category
 *  <li>{array} parentIds - The list of parent ids
 *  <li>{boolean} includeSubAssets - Whether or not to include the immediate children
 * </ul>
 */
function addAllReadings(currentContext, axiosInstance, options) {
	const key = JSON.stringify(options);
	if (sentRequests[key]) {
		// Same request already sent
		return sentRequests[key];
	}
	sentRequests[key] = axiosInstance.get(undefined, {
		params: {
			dbids: options.ids ? options.ids.join(',') : undefined,
			returnUiName: true,
			attributeFilter: options.requestCategory,
			parentIds: options.parentIds ? options.parentIds.join(',') : undefined,
			includeSubAssets: options.includeSubAssets,
			deriveAddress: options.requestCategory === 'LOCATION' || options.requestCategory === 'LOCATION_HISTORY',
			originalActor: true
		}
	}).then((response) => {
		delete sentRequests[key];
		currentContext.commit('addToCache', {
			bundles: response.data,
			requestCategory: options.requestCategory
		});
	}, () => {
		delete sentRequests[key];
	});

	return sentRequests[key];
}

/**
 * Parses the current request categories and ids used by the store into a compact format for
 * updating the associated readings with minimal calls
 *
 * @param {object} currentContext - The current context of the module
 * @param {object} defaultOptions - The default options for the request
 * @returns {array<object>} The list of request parameters
 */
function parseIntoPubSubRequestOptions(currentContext, defaultOptions) {
	const allCategories = currentContext.state.requestCategories;
	const categoryById = {};
	const similarIdsByCategory = {};

	// Groups the used request category by id - { 1: RC1|RC2, 2: RC1|RC2, 3: RC3 }
	_.each(allCategories, (usedByCategory, requestCategory) => {
		_.each(usedByCategory, (readings, id) => {
			if (!_.isEmpty(readings)) {
				categoryById[id] = categoryById[id]
					? `${categoryById[id]}|${requestCategory}`
					: requestCategory;
			}
		});
	});

	// Groups the used id by similar request categories - { RC1|RC2: [1, 2],  RC3: [3] }
	_.each(categoryById, (category, id) => {
		if (similarIdsByCategory[category]) {
			similarIdsByCategory[category].push(id);
		} else {
			similarIdsByCategory[category] = [id];
		}
	});

	// Transforms the similar ids/category into the request parameters
	return _.map(similarIdsByCategory, (ids, requestCategories) => ({
		params: {
			...defaultOptions,
			dbids: ids.join(','),
			returnUiName: true,
			attributeFilter: requestCategories,
			deriveAddress: requestCategories.includes('LOCATION'),
			originalActor: true
		}
	}));
}

/**
 * Generates the api action methods that are hooked up with the modules cache
 * <ul>This provides the following apis:
 *	<li>get - GET w/ ids + request category
 * </ul>
 * Note: This also provides 'clean' which is added as a root action instead of a module action
 *
 * @param {axios} axiosInstance - The axios instance to use
 * @return {object} - The standard api interactions
 */
function generateModuleActions(axiosInstance) {
	return {
		/**
		 * Populates the cache with the desired asset readings
		 *
		 * @param {object} currentContext - The context of the current module
		 * @param {object} options - The get options
		 * <ul>Consists of:
		 *	<li>{array<Number>|number} ids - The asset ids to fetch the readings of
		 *	<li>{array<Number>|number} parentIds - The asset parent ids to fetch the readings of
		 *	<li>{string} requestCategory - The request category of the readings of interest
		 *	<li>{boolean} includeSubAssets - Whether or not to fetch the children readings
		 * 	<li>{boolean} ignore - Always hit the api to get new readings
		 * </ul>
		 * @return {Promise} Resolved when the request has finished
		 */
		get(currentContext, options) {
			if (options.includeSubAssets || options.parentIds || options.ignore) {
				return addAllReadings(
					currentContext,
					axiosInstance,
					{
						ids: options.ids && !_.isArray(options.ids)
							? [options.ids]
							: options.ids,
						requestCategory: options.requestCategory,
						parentIds: options.parentIds && !_.isArray(options.parentIds)
							? [options.parentIds]
							: options.parentIds,
						includeSubAssets: options.includeSubAssets
					}
				);
			}
			return addMissingReadings(
				currentContext,
				axiosInstance,
				_.isArray(options.ids) ? options.ids : [options.ids],
				options.requestCategory
			);
		},

		/**
		 * Updates the current state of the store with readings that have changed since the last
		 * time (using modifiedSince)
		 *
		 * @param {object} currentContext - The context of the current module
		 * @param {object} options - The additional reading request options to fetch with
		 * @returns {Promise} The promise resolved with the next modifiedSince to use
		 */
		updateFromInterval(currentContext, options) {
			let serverTime = Infinity;
			const batchedParams = parseIntoPubSubRequestOptions(currentContext, options);

			return Promise.all(
				_.map(batchedParams, (params) => axiosInstance.get(undefined, params).then((response) => {
					currentContext.commit('addToCache', { bundles: response.data });
					serverTime = Math.min(serverTime, response.headers['pt-server-time']);
				}))
			).then(() => ({ modifiedSince: _.isFinite(serverTime) ? serverTime : Date.now() }));
		},

		...moduleClean
	};
}

/**
 * Creates the current readings store module
 *
 * @return {object} The current readings module
 */
export default function () {
	const axiosInstance = createAxiosInstance('/api/assets/readings/latest');
	return {
		state: {
			readings: {},
			requestCategories: {}
		},
		namespaced: true,

		// Called by using (this.)$store.commit({module name}/{mutation function}, ...)
		mutations: generateModuleMutations(),

		// Called by using (this.)$store.getters[{module name}/{getter function}](...)
		getters: generateModuleGetters(axiosInstance),

		// Called by using (this.)$store.dispatch({module name}/{action function}, ...)
		actions: generateModuleActions(axiosInstance)
	};
}
