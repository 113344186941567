import { getContrastingColor } from '@/utils/color';

const FILE_ENDING = '.png';
const ICON_FILE_PATH = {
	smallIcon: '/images/actor-class-icons/asset_tree_icon/',
	mediumIcon: '/images/actor-class-icons/asset_avatar_mini/',
	largeIcon: '/images/actor-class-icons/asset_avatar/'
};

const ICON_SIZES_CLASSES = {
	smallIcon: 'actor-image-small-icon',
	mediumIcon: 'actor-image-medium-icon',
	largeIcon: 'actor-image-large-icon'
};

const COLOR_PREFIX = '#';
const USER_SIZES_CLASSES = {
	smallIcon: 'user-image-small-icon',
	mediumIcon: 'user-image-medium-icon',
	largeIcon: 'user-image-large-icon'
};

/** {String[]} The list of attributes that trigger an update when they are changed */
const OBSERVED_ATTRIBUTES = [
	'iconsize',
	'icon-size',
	'actorclassid',
	'actor-class-id',
	'iconsetfilename',
	'icon-set-file-name',
	'class',
	'title',
	'actortype',
	'actor-type',
	'actorname',
	'actor-name'
];

/** {Object} The list of attributes that should not be copied to the image element */
const IGNORED_ATTRIBUTES = {
	class: true,
	style: true
};

const ACTOR_TYPE_TO_ELEMENT = {
	USER: 'SPAN',
	DEFAULT: 'IMG'
};

/**
 * Populates the actor class to icon file name
 *
 * @param {Number|String} actorClassId - The id of the actor class to populate
 * @return {Promise} The state of the request
 */
function populateActorClass(actorClassId) {
	return window.PT_STORE.dispatch('actorClasses/get', {
		key: actorClassId
	});
}

/**
 * Custom class definition for an actor image
 * <ul>This will set the image based on:
 *  <li>{String} iconSize - The size of the icon (smallIcon, mediumIcon, largeIcon).
 *  	Defaults smallIcon
 *  <li>{String} iconSetFileName - The name of the icon to show
 *  <li>{String} actorClassId - The id of the actor class.
 * </ul>
 */
export default class extends HTMLElement {
	constructor() {
		super();

		this.updateIcon();

		// Hide this element, so it doesn't interfere with the flow of the document
		setTimeout(() => {
			this.style.display = 'none';
		}, 1);
	}

	/**
	 * @returns {string} The name of the custom tag
	 */
	static get tagName() {
		return 'actor-img';
	}

	/**
	 * @returns {string[]} The attributes that trigger the attributeChangedCallback
	 */
	static get observedAttributes() {
		return OBSERVED_ATTRIBUTES;
	}

	/**
	 * Called when the watched attributes update.
	 * Synchronizes the image with the updated attributes
	 */
	attributeChangedCallback() {
		this.updateIcon();
	}

	/**
	 * Called when the custom element is attached to the document
	 * Inserts the image element
	 */
	connectedCallback() {
		if (this.parentNode && this.actorImage) {
			this.parentNode.insertBefore(this.actorImage, this);
		}
	}

	/**
	 * Called when the custom element is moved between documents
	 * Updates the location of the image element
	 */
	adoptedCallback() {
		if (this.parentNode && this.actorImage) {
			this.parentNode.insertBefore(this.actorImage, this);
		}
	}

	/**
	 * Called when the custom element is removed from the document
	 * Removes the image element
	 */
	disconnectedCallback() {
		if (this.actorImage && this.actorImage.parentNode) {
			this.actorImage.parentNode.removeChild(this.actorImage);
		}
	}

	/**
	 * Updates the icon to match the current actor-img attributes
	 * Handles swapping the icon's element based on the actor type
	 */
	updateIcon() {
		const type = CURRENT_USER.hasAccessToFeature('user_permission', 'user_avatars_beta')
			? this.getAttribute('actor-type') || this.getAttribute('actorType')
			: undefined;
		const element = ACTOR_TYPE_TO_ELEMENT[type] || ACTOR_TYPE_TO_ELEMENT.DEFAULT;

		if (!this.actorImage) {
			this.actorImage = document.createElement(element);
		} else if (this.actorImage.tagName.toUpperCase() !== element) {
			this.disconnectedCallback();
			this.actorImage = document.createElement(element);
			this.connectedCallback();
		}

		if (type === 'USER') {
			this.updateUser();
		} else {
			this.updateImage();
		}
	}

	/**
	 * Updates the image to match the current actor-img attributes
	 */
	updateImage() {
		const iconSize = this.getAttribute('icon-size') || this.getAttribute('iconSize');
		if (!iconSize) {
			return;
		}

		const actorClassId = this.getAttribute('actor-class-id') || this.getAttribute('actorClassId');
		const iconFileName = this.getAttribute('icon-set-file-name') || this.getAttribute('iconSetFileName');

		const sizePath = ICON_FILE_PATH[iconSize];
		const sizeClass = ICON_SIZES_CLASSES[iconSize];
		this.actorImage.className = `${sizeClass} ${this.className}`;

		// Copy attributes from the custom web element to the image
		this.getAttributeNames().forEach((name) => {
			if (!IGNORED_ATTRIBUTES[name]) {
				this.actorImage.setAttribute(name, this.getAttribute(name));
			}
		});

		if (iconFileName) {
			this.actorImage.src = sizePath + iconFileName + FILE_ENDING;
		} else if (PT_STORE.state.actorClasses.cache[actorClassId]) {
			this.actorImage.src = sizePath
				+ PT_STORE.state.actorClasses.cache[actorClassId].iconSetFileName
				+ FILE_ENDING;
		} else if (actorClassId) {
			populateActorClass(actorClassId).then(() => {
				this.actorImage.src = sizePath
					+ PT_STORE.state.actorClasses.cache[actorClassId].iconSetFileName
					+ FILE_ENDING;
			});
		}
	}

	/**
	 * Updates the user icon to match the current actor-img attributes
	 */
	updateUser() {
		const iconSize = this.getAttribute('icon-size') || this.getAttribute('iconSize');
		const name = this.getAttribute('actor-name') || this.getAttribute('actorName');
		if (!iconSize || !name) {
			return;
		}

		this.actorImage.textContent = name[0];
		const sizeClass = USER_SIZES_CLASSES[iconSize];
		this.actorImage.className = `${sizeClass} ${this.className}`;

		// Copy attributes from the custom web element to the image
		this.getAttributeNames().forEach((n) => {
			if (!IGNORED_ATTRIBUTES[n]) {
				this.actorImage.setAttribute(n, this.getAttribute(n));
			}
		});

		const actorClassId = this.getAttribute('actor-class-id') || this.getAttribute('actorClassId');

		if (PT_STORE.state.actorClasses.cache[actorClassId]) {
			this.actorImage.style.backgroundColor = COLOR_PREFIX + PT_STORE.state.actorClasses.cache[actorClassId].mapFGColor;
			this.actorImage.style.color = getContrastingColor(COLOR_PREFIX + PT_STORE.state.actorClasses.cache[actorClassId].mapFGColor);
		} else if (actorClassId) {
			populateActorClass(actorClassId).then(() => {
				this.actorImage.style.backgroundColor = COLOR_PREFIX + PT_STORE.state.actorClasses.cache[actorClassId].mapFGColor;
				this.actorImage.style.color = getContrastingColor(COLOR_PREFIX + PT_STORE.state.actorClasses.cache[actorClassId].mapFGColor);
			});
		}
	}
}
