/** {RegExp} All pubsub list requests will end with this */
const PUB_SUB_REQUEST = /\.pubsub$/;

/** {String} An indicator for a parameter not being set */
const EMPTY_PARAM = '/';

/**
 * <ul>The replacer function for JSON.stringify to exclude the following parameters:
 *  <li>modifiedSince
 * </ul>
 *
 * @param {String} key - The key
 * @param {*} value - The value
 * @return {*} Undefined if the key should be excluded, otherwise the value
 */
function pubsubParamReplacer(key, value) {
	return key === 'modifiedSince' ? undefined : value;
}

/**
 * Generates a unique request string based on the request options
 *
 * @param {axios} axiosInstance - The axios instance that is being used
 * @param {Object} options - The options of the request
 * @returns {String} The string id of the request
 */
export default function (axiosInstance, options) {
	// Replace the modified since for pub sub requests for determining the request id
	const paramReplacer = options.linkedKey && PUB_SUB_REQUEST.test(options.linkedKey)
		? pubsubParamReplacer
		: undefined;

	return axiosInstance.defaults.baseURL
		+ (options.customerId ? `${options.customerId}-customer/` : EMPTY_PARAM)
		+ (options.useMy ? 'my/' : EMPTY_PARAM)
		+ (options.replaceUrl ? options.replaceUrl : EMPTY_PARAM)
		+ (options.injectedUrl ? options.injectedUrl : EMPTY_PARAM)
		+ (options.key ? `${options.key}` : EMPTY_PARAM)
		+ (options.params ? JSON.stringify(options.params, paramReplacer) : EMPTY_PARAM);
}
