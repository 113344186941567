window.SUBSCRIPTION_INTERVALS = window.SUBSCRIPTION_INTERVALS || {};

/**
 * {Object<String, String>} The available categories for synchronized subscribers
 * Custom intervals should be added to {@link ./ui/pubsub_interval.json}
 */
export const CATEGORIES = {
	ALARMS: 'ALARMS',
	COMMUNICATIONS: 'COMMUNICATIONS',
	SYSTEM: 'SYSTEM',
	HOS: 'HOS',
	JOBS: 'JOBS',
	DEVICES: 'DEVICES',
	CONTROL: 'CONTROL',
	DASHBOARDS: 'DASHBOARDS',
	EVENTS: 'EVENTS',
	ACTORS: 'ACTORS',
	SINGLE_ASSET_MAP: 'SINGLE_ASSET_MAP'
};

/**
 * {Object} The synced subscriptions.
 * <ul>Consists of:
 *  <li>{number} timeoutId - The id of the timeout
 *  <li>{array<Function>} subscribers - The list of subscribed callback functions
 *  <li>{CATEGORIES|Number} key - The key of the schedule
 * </ul>
 *
 * Note: This is exposed for testing purposes only
 */
export const schedules = {};

/**
 * Helper to get the time interval of a subscription category
 *
 * @param {String} category - The subscription category
 * @returns {number} The interval of the subscription
 */
function getInterval(category) {
	return window.SUBSCRIPTION_INTERVALS[category] === undefined ? window.SUBSCRIPTION_INTERVALS.DEFAULT : window.SUBSCRIPTION_INTERVALS[category];
}

/**
 * Removes the subscribed callback function from its category
 * If category is given it will look through all schedules to find the matching callback function.
 * Note: calling the function returned from {@link subscribe} will call this function
 *
 * @param {Function} callback - The subscriber call back function
 * @param {String|Number} category - The category of the callback
 */
export function unsubscribe(callback, category) {
	const schedule = category
		? schedules[category]
		: Object.values(schedules).find(({ subscribers }) => subscribers.includes(callback));

	if (schedule) {
		const index = schedule.subscribers.indexOf(callback);
		if (index > -1) {
			schedule.subscribers.splice(index, 1);
		}
		if (!schedule.subscribers.length) {
			clearTimeout(schedule.timeoutId);
			delete schedules[schedule.key];
		}
	}
}

/**
 * Runs the subscribers for a particular category
 * This will reschedule the subscribers once all subscribers have been resolved (to prevent request
 * stacking)
 * Note: This is exposed for testing purposes only
 *
 * @param {String|Number} category - The category to run the subscribers for
 * @return {Promise} The state of the subscription update
 */
export function runSubscription(category) {
	return Promise.allSettled(
		schedules[category].subscribers.map((subscriber) => subscriber())
	).finally(() => {
		const interval = getInterval(category);
		if (interval > 0) {
			schedules[category].timeoutId = setTimeout(runSubscription, interval, category);
		} else {
			delete schedules[category];
		}
	});
}

/**
 * Schedules the callback for the given category
 * If no category is given it will create a separate category based on the current time
 * The callback must return a promise that will be resolved/rejected when the asynchronous update is
 * finished (prevent long-running updates from stacking)
 *
 * @param {Function<Promise>} callback - The function to call when it is time to update
 * @param {String|Number} category - The category. Defaults to the current time
 * @return {Function} The unsubscribe function
 */
export function subscribe(callback, category = Date.now()) {
	const interval = getInterval(category);
	if (interval > 0) {
		if (!schedules[category]) {
			schedules[category] = {
				timeoutId: setTimeout(runSubscription, interval, category),
				subscribers: [],
				key: category
			};
		}
		schedules[category].subscribers.push(callback);
	}

	return unsubscribe.bind(undefined, callback, category);
}
