/**
 * Simulates PHP's date function
 */
/* eslint-disable */
Date.prototype.pt_format = function (format) {
	let returnStr = ''; const replace = Date.replaceChars; for (let i = 0; i < format.length; i++) {
		const curChar = format.charAt(i); if (i - 1 >= 0 && format.charAt(i - 1) == '\\') {
			returnStr += curChar;
		} else if (replace[curChar]) {
			returnStr += replace[curChar].call(this);
		} else if (curChar != '\\') {
			returnStr += curChar;
		}
	} return returnStr;
}; Date.replaceChars = {
	shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
	longMonths: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
	shortDays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
	longDays: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
	d() {
		return (this.getDate() < 10 ? '0' : '') + this.getDate();
	},
	D() {
		return Date.replaceChars.shortDays[this.getDay()];
	},
	j() {
		return this.getDate();
	},
	l() {
		return Date.replaceChars.longDays[this.getDay()];
	},
	N() {
		return this.getDay() + 1;
	},
	S() {
		return (this.getDate() % 10 == 1 && this.getDate() != 11 ? 'st' : (this.getDate() % 10 == 2 && this.getDate() != 12 ? 'nd' : (this.getDate() % 10 == 3 && this.getDate() != 13 ? 'rd' : 'th')));
	},
	w() {
		return this.getDay();
	},
	z() {
		const d = new Date(this.getFullYear(), 0, 1); return Math.ceil((this - d) / 86400000);
	},
	W() {
		const d = new Date(this.getFullYear(), 0, 1); return Math.ceil((((this - d) / 86400000) + d.getDay() + 1) / 7);
	},
	F() {
		return Date.replaceChars.longMonths[this.getMonth()];
	},
	m() {
		return (this.getMonth() < 9 ? '0' : '') + (this.getMonth() + 1);
	},
	M() {
		return Date.replaceChars.shortMonths[this.getMonth()];
	},
	n() {
		return this.getMonth() + 1;
	},
	t() {
		const d = new Date(); return new Date(d.getFullYear(), d.getMonth(), 0).getDate();
	},
	L() {
		const year = this.getFullYear(); return (year % 400 == 0 || (year % 100 != 0 && year % 4 == 0));
	},
	o() {
		const d = new Date(this.valueOf()); d.setDate(d.getDate() - ((this.getDay() + 6) % 7) + 3); return d.getFullYear();
	},
	Y() {
		return this.getFullYear();
	},
	y() {
		return (`${this.getFullYear()}`).substr(2);
	},
	a() {
		return this.getHours() < 12 ? 'am' : 'pm';
	},
	A() {
		return this.getHours() < 12 ? 'AM' : 'PM';
	},
	B() {
		return Math.floor((((this.getUTCHours() + 1) % 24) + this.getUTCMinutes() / 60 + this.getUTCSeconds() / 3600) * 1000 / 24);
	},
	g() {
		return this.getHours() % 12 || 12;
	},
	G() {
		return this.getHours();
	},
	h() {
		return ((this.getHours() % 12 || 12) < 10 ? '0' : '') + (this.getHours() % 12 || 12);
	},
	H() {
		return (this.getHours() < 10 ? '0' : '') + this.getHours();
	},
	i() {
		return (this.getMinutes() < 10 ? '0' : '') + this.getMinutes();
	},
	s() {
		return (this.getSeconds() < 10 ? '0' : '') + this.getSeconds();
	},
	u() {
		const m = this.getMilliseconds(); return (m < 10 ? '00' : (m < 100 ? '0' : '')) + m;
	},
	e() {
		return 'Not Yet Supported';
	},
	I() {
		return 'Not Yet Supported';
	},
	O() {
		return `${(-this.getTimezoneOffset() < 0 ? '-' : '+') + (Math.abs(this.getTimezoneOffset() / 60) < 10 ? '0' : '') + (Math.abs(this.getTimezoneOffset() / 60))}00`;
	},
	P() {
		return `${(-this.getTimezoneOffset() < 0 ? '-' : '+') + (Math.abs(this.getTimezoneOffset() / 60) < 10 ? '0' : '') + (Math.abs(this.getTimezoneOffset() / 60))}:00`;
	},
	T() {
		const m = this.getMonth(); this.setMonth(0); const result = this.toTimeString().replace(/^.+ \(?([^\)]+)\)?$/, '$1'); this.setMonth(m); return result;
	},
	Z() {
		return -this.getTimezoneOffset() * 60;
	},
	c() {
		return this.pt_format('Y-m-d\\TH:i:sP');
	},
	r() {
		return this.toString();
	},
	U() {
		return this.getTime() / 1000;
	}
};
