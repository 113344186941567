const TEN_MINUTES = 600000;
/**
 * Initializes the pub sub interval for the provided module.
 * Note: This will update the provided options with the updated options when the response is
 * successful
 *
 * @param {Vuex.Store} store - The main store
 * @param {string} moduleName - The name of the module to call pub-sub on
 * @param {object} options - The options to call dispatch - 'updateFromInterval' with
 */
export default function (store, moduleName, options) {
	function initializePubSubInterval() {
		store.dispatch(`${moduleName}/updateFromInterval`, options).then((updatedOptions) => {
			_.extend(options, updatedOptions);
			setTimeout(initializePubSubInterval, window.SUBSCRIPTION_INTERVAL || TEN_MINUTES);
		}, () => {
			setTimeout(initializePubSubInterval, window.SUBSCRIPTION_INTERVAL || TEN_MINUTES);
		});
	}

	setTimeout(initializePubSubInterval, window.SUBSCRIPTION_INTERVAL || TEN_MINUTES);
}
