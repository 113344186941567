/* eslint-disable max-lines-per-function */
import createAPIStoreModule from '@/store/modules/api-modules';
import createCurrentReadingStoreModule from '@/store/modules/current-reading-module';
import createCurrentUserPreferencesStoreModule from '@/store/modules/user-preference-module';
import createAppStateModule from '@/store/modules/app-state-module';

/**
 * The created API modules that can be shared across different stores.
 *
 * To create a new api store module call createApiStoreModule with the parameters
 * {string} url - The api url that the module will be using.
 *		Note: There is no leading and trailing slash in the url (lineage/api/actors)
 *
 * {string} dataKey - The unique identifier for the data returned from an api call.
 *		Typically this will be dbId/id depending on the api endpoint
 *
 * {object<string, string|function>} moduleMap - The map of attribute names to what other api
 *		module to store the data in. This will be {module name}. If using a function
 *		the entire data api object will be passed as the first argument of the function.
 *		Example - For an alarm with an acknoweledger { acknowledger: 'actors' }
 *
 * {object<string, string|function>} linkMap - The map of attribute names to what other api
 *		module to store the fetched data. The attribute corresponds to a url which will need
 * to be retrieved before it can be added to the desired module. If using a function the the
 * original api object will be passed as the first argument to the function. The resulting link
 * can be accessed from the stored modules cache via
 *		(this.)$store.getters[{module name}/get]({ linkedKey: linkKey }).
 *		Example - Job with events { events: 'events' } retrieved by using
 *		(this.)$store.getters[events/get]({ linkedKey: job.events }).
 */
export default function () {
	return {
		accelerationContext: createAPIStoreModule({
			url: '/api/views/accelerationcontext'
		}),
		actors: createAPIStoreModule({
			url: '/api/actors',
			dataKey: 'dbId',
			linkMap: {
				url(data) {
					return `${data.actorType.toLowerCase()}s`;
				}
			}
		}),
		actorClasses: createAPIStoreModule({
			url: '/api/actorClasses',
			dataKey: 'dbId',
			noClean: true
		}),
		actorPermissions: createAPIStoreModule({
			url: '/api/actorPermissions'
		}),
		actorTree: createAPIStoreModule({
			url: '/api/views/actor/children',
			moduleMap: {
				actor: 'actors'
			}
		}),
		adminAssetList: createAPIStoreModule({
			url: '/api/views/adminList/assets',
			moduleMap: {
				asset: 'actors',
				device: 'actors',
				secondaryDevices: 'actors'
			}
		}),
		adminCustomerList: createAPIStoreModule({
			url: '/api/views/adminList/customers',
			moduleMap: {
				customer: 'actors'
			}
		}),
		adminDeviceList: createAPIStoreModule({
			url: '/api/views/adminList/devices',
			moduleMap: {
				assets: 'actors',
				customer: 'actors'
			}
		}),
		adminDriverList: createAPIStoreModule({
			url: '/api/views/adminList/drivers',
			moduleMap: {
				driver: 'actors',
				driverInfo: 'driverInfo'
			}
		}),
		adminLandmarkList: createAPIStoreModule({
			url: '/api/views/adminList/landmarks',
			moduleMap: {
				landmarkGroups: 'landmarkgrps'
			}
		}),
		adminMobileDeviceList: createAPIStoreModule({
			url: '/api/views/adminList/mobileDevices',
			moduleMap: {
				device: 'actors',
				user: 'actors',
				vehicle: 'actors'
			}
		}),
		adminUserList: createAPIStoreModule({
			url: '/api/views/adminList/users',
			moduleMap: {
				user: 'actors',
				groups: 'actors'
			}
		}),
		alarmConfigs: createAPIStoreModule({
			url: '/api/alarmConfigs',
			dataKey: 'dbId',
			moduleMap: {
				owner: 'actors',
				collection: 'actors'
			}
		}),
		alarmConfigTemplates: createAPIStoreModule({
			url: '/api/alarmConfigTemplates',
			dataKey: 'id'
		}),
		alarms: createAPIStoreModule({
			url: '/api/alarms',
			dataKey: 'id',
			moduleMap: {
				acknowledger: 'actors',
				actor: 'actors'
			},
			linkMap: {
				eventLink: 'events',
				readingsLink: 'readings',
				resultLink: 'result'
			}
		}),
		applicationBundles: createAPIStoreModule({
			url: '/api/applicationBundles',
			dataKey: 'id'
		}),
		approvalChains: createAPIStoreModule({
			dataKey: 'id'
		}),
		appState: createAppStateModule(),
		assignedApplicationBundles: createAPIStoreModule({
			url: '/api/assignedApplicationBundles',
			dataKey: 'id'
		}),
		assetDefinitions: createAPIStoreModule({
			url: '/api/definitions',
			dataKey: 'id'
		}),
		assetImages: createAPIStoreModule({
			url: '/api/files/avatars',
			dataKey: 'id',
			moduleMap: {
				linkedActors: 'actors'
			}
		}),
		assetModels: createAPIStoreModule({
			url: '/api/models/asset',
			dataKey: 'dbId',
			noClean: true
		}),
		assets: createAPIStoreModule({
			url: '/api/assets',
			dataKey: 'dbId'
		}),
		auditLogs: createAPIStoreModule({
			url: '/api/auditlogs'
		}),
		cameraInfo: createAPIStoreModule({
			url: '/api/lightmetrics/cameraConfigurations',
			dataKey: 'assetId'
		}),
		carriers: createAPIStoreModule({
			url: '/api/carriers',
			dataKey: 'dbId'
		}),
		coachingFlags: createAPIStoreModule({
			url: '/api/coachingFlags',
			dataKey: 'id',
			moduleMap: {
				assignee: 'actors',
				createdBy: 'actors'
			}
		}),
		collections: createAPIStoreModule({
			url: '/api/collections',
			dataKey: 'dbId',
			moduleMap: {
				collection: 'collections'
			},
			linkMap: {
				rootActorsLink: 'actors'
			}
		}),
		comments: createAPIStoreModule({
			url: '/api/comments',
			dataKey: 'id'
		}),
		controlModels: createAPIStoreModule({
			url: '/api/controlModels',
			dataKey: 'name'
		}),
		controlRequests: createAPIStoreModule({
			url: '/api/controlRequests',
			dataKey: 'id',
			moduleMap: {
				requester: 'actors',
				actor: 'actors'
			}
		}),
		conversations: createAPIStoreModule({
			url: '/api/conversations',
			dataKey: 'id',
			moduleMap: {
				users: 'actors',
				groups: 'actors',
				lastMessage: 'conversationMessages'
			}
		}),
		conversationMessages: createAPIStoreModule({
			url: '/api/conversationMessages',
			dataKey: 'id',
			moduleMap: {
				from: 'actors'
			}
		}),
		conversationStates: createAPIStoreModule({
			url: '/api/conversationState',
			dataKey: 'conversationId',
			moduleMap: {
				user: 'actors'
			}
		}),
		cronExpressions: createAPIStoreModule({
			url: '/api/cronExpressions',
			dataKey: 'dbId'
		}),
		currentReadings: createCurrentReadingStoreModule(),
		currentUserPreferences: createCurrentUserPreferencesStoreModule(),
		customers: createAPIStoreModule({
			url: '/api/customers',
			dataKey: 'dbId'
		}),
		dashboards: createAPIStoreModule({
			url: '/api/dashboards',
			dataKey: 'id'
		}),
		dashboardList: createAPIStoreModule({
			url: '/api/dashboards',
			dataKey: 'id'
		}),
		dashboardTemplates: createAPIStoreModule({
			url: '/api/dashboardTemplates',
			dataKey: 'id'
		}),
		deviceModels: createAPIStoreModule({
			url: '/api/models/device',
			dataKey: 'id'
		}),
		devices: createAPIStoreModule({
			url: '/api/devices',
			dataKey: 'dbId'
		}),
		disputes: createAPIStoreModule({
			url: '/api/disputes',
			dataKey: 'id',
			moduleMap: {
				actor: 'actors',
				createdBy: 'actors',
				closedBy: 'actors'
			}
		}),
		divisions: createAPIStoreModule({
			url: '/api/divisions',
			dataKey: 'dbId'
		}),
		drivers: createAPIStoreModule({
			url: '/api/drivers',
			dataKey: 'dbId'
		}),
		driverInfo: createAPIStoreModule({
			url: '/api/users',
			dataKey: 'dbId'
		}),
		drivewyzeSubscriptions: createAPIStoreModule({
			url: '/api/drivewyze/subscriptions',
			dataKey: 'id',
			moduleMap: {
				linkedActors: 'actors'
			}
		}),
		eldRecords: createAPIStoreModule({
			url: '/api/eldrecords',
			dataKey: 'id'
		}),
		eldLogbookView: createAPIStoreModule({
			url: '/api/views/logbook'
		}),
		eldView: createAPIStoreModule({
			url: '/api/views/users/hosRecap'
		}),
		eventRules: createAPIStoreModule({
			url: '/api/eventRules',
			dataKey: 'id'
		}),
		eventModels: createAPIStoreModule({
			url: '/api/models/event',
			dataKey: 'dbId'
		}),
		events: createAPIStoreModule({
			url: '/api/events',
			dataKey: 'id'
		}),
		fileLibrary: createAPIStoreModule({
			url: '/api/files',
			dataKey: 'id',
			moduleMap: {
				linkedActors: 'actors'
			}
		}),
		formDocumentList: createAPIStoreModule({
			url: '/api/formDocuments',
			dataKey: 'id',
			moduleMap: {
				submittedBy: 'actors',
				modifiedBy: 'actors'
			}
		}),
		formDocuments: createAPIStoreModule({
			url: '/api/formDocuments',
			dataKey: 'id',
			moduleMap: {
				submittedBy: 'actors',
				modifiedBy: 'actors',
				postApprovalChain: 'approvalChains'
			}
		}),
		formDocumentTemplates: createAPIStoreModule({
			url: '/api/formDocumentTemplates',
			dataKey: 'versionedId'
		}),
		groups: createAPIStoreModule({
			url: '/api/groups',
			dataKey: 'dbId'
		}),
		hosConfigs: createAPIStoreModule({
			url: '/api/hosconfigs',
			dataKey: 'driverId'
		}),
		historicalHosConfigs: createAPIStoreModule({
			url: '/api/hosconfigs'
		}),
		hosRuleSets: createAPIStoreModule({
			url: '/api/hosRulesets',
			dataKey: 'id'
		}),
		hoursSummary: createAPIStoreModule({
			url: '/api/hoursSummary',
			dataKey: 'driverId'
		}),
		iconSets: createAPIStoreModule({
			url: '/api/iconSets',
			dataKey: 'dbId'
		}),
		inspections: createAPIStoreModule({
			url: '/api/inspectionData',
			dataKey: 'id'
		}),
		inspectionForms: createAPIStoreModule({
			url: '/api/inspectionForms',
			dataKey: 'dbId'
		}),
		inspectionTemplates: createAPIStoreModule({
			url: '/api/inspectionTemplates',
			dataKey: 'id'
		}),
		jobAssignable: createAPIStoreModule({
			url: '/api/views/assignableList'
		}),
		jobs: createAPIStoreModule({
			url: '/api/jobs',
			dataKey: 'dbId',
			linkMap: {
				eventsLink: 'events',
				currentReadingsLink: 'actors'
			}
		}),
		jobList: createAPIStoreModule({
			url: '/api/jobs',
			dataKey: 'dbId'
		}),
		jobQueues: createAPIStoreModule({
			url: '/api/jobQueues',
			dataKey: 'queueId'
		}),
		jobTemplates: createAPIStoreModule({
			url: '/api/jobs/templates',
			dataKey: 'dbId'
		}),
		landmarkgrps: createAPIStoreModule({
			url: '/api/landmarkGroups',
			dataKey: 'dbId'
		}),
		landmarks: createAPIStoreModule({
			url: '/api/landmarks',
			dataKey: 'dbId'
		}),
		latestReading: createAPIStoreModule({
			url: '/api/readings/latest'
		}),
		linkedActors: createAPIStoreModule({
			url: '/api/actors/linkedActors',
			dataKey: 'dbId'
		}),
		midmDeviceRemoteSession: createAPIStoreModule({
			url: '/api/midmDeviceRemoteSession',
			dataKey: 'deviceSerialNumber'
		}),
		nextReading: createAPIStoreModule({
			url: '/api/readings/next'
		}),
		nonKeyData: createAPIStoreModule({
			url: '/api'
		}),
		notices: createAPIStoreModule({
			url: '/api/notices',
			dataKey: 'id',
			moduleMap: {
				from: 'actors',
				users: 'actors',
				groups: 'actors',
				ovFiles: 'fileLibrary'
			}
		}),
		noticeInstances: createAPIStoreModule({
			url: '/api/noticeInstances',
			dataKey: 'noticeId',
			moduleMap: {
				notice: 'notices',
				user: 'actors'
			}
		}),
		noticeRecipients: createAPIStoreModule({
			url: '/api',
			moduleMap: {
				user: 'actors'
			}
		}),
		passwordPolicies: createAPIStoreModule({
			url: '/api/passwordPolicies',
			dataKey: 'customer.dbId'
		}),
		quickNotes: createAPIStoreModule({
			url: '/api/quickNotes',
			dataKey: 'id'
		}),
		rangeReadings: createAPIStoreModule({
			url: '/api/readings'
		}),
		readings: createAPIStoreModule({
			url: '/api/assets'
		}),
		regions: createAPIStoreModule({
			url: '/api/regions',
			dataKey: 'dbId'
		}),
		reportinglocations: createAPIStoreModule({
			url: '/api/reportingLocations',
			dataKey: 'dbId'
		}),
		reportTemplates: createAPIStoreModule({
			url: '/api/reportTemplates',
			dataKey: 'id'
		}),
		result: createAPIStoreModule({
			url: '/api/result',
			dataKey: 'id'
		}),
		routes: createAPIStoreModule({
			url: '/api/routes',
			dataKey: 'id'
		}),
		routeDetails: createAPIStoreModule({
			url: '/api/views/routeDetails',
			moduleMap: {
				route: 'routes',
				jobs: 'jobs',
				targets: 'assets'
			}
		}),
		savedReports: createAPIStoreModule({
			url: '/api/savedReports',
			dataKey: 'dbId'
		}),
		scorecards: createAPIStoreModule({
			url: '/api/scorecards',
			dataKey: 'id'
		}),
		scorecardStatOverrides: createAPIStoreModule({
			url: '/api/scorecardStatOverrides'
		}),
		supportCenters: createAPIStoreModule({
			url: '/api/supportCenters',
			dataKey: 'dbId'
		}),
		tagKeys: createAPIStoreModule({
			url: '/api/tagKeys',
			dataKey: 'id'
		}),
		tractors: createAPIStoreModule({
			url: '/api/tractors',
			dataKey: 'dbId'
		}),
		trailers: createAPIStoreModule({
			url: '/api/trailers',
			dataKey: 'dbId'
		}),
		userPreference: createAPIStoreModule({
			url: '/api/users'
		}),
		users: createAPIStoreModule({
			url: '/api/users',
			dataKey: 'dbId'
		}),
		videos: createAPIStoreModule({
			url: '/api/videos'
		}),
		warranties: createAPIStoreModule({
			url: '/api/warranties',
			dataKey: 'id'
		})
	};
}
