import Vue from 'vue';

/**
 * Plugin definition for pt-global-plugin
 *
 * <ul>Adds the helper variables:
 *  <li>$i18n
 *  <li>$IS_CHROME
 *  <li>$IS_EDGE
 *  <li>$IS_FIREFOX
 *  <li>$IS_IE
 *  <li>$IS_SAFARI
 * </ul>
 */
export default {
	/**
	 * Plugin install function
	 *
	 * @param {Vue} vue - The main vue instance to use
	 */
	install(vue) {
		if (window.i18n) {
			vue.prototype.$i18n = Vue.observable(window.i18n);
		}

		// IS_BROWSER
		vue.prototype.$IS_CHROME = IS_CHROME;
		vue.prototype.$IS_EDGE = IS_EDGE;
		vue.prototype.$IS_FIREFOX = IS_FIREFOX;
		vue.prototype.$IS_IE = IS_IE;
		vue.prototype.$IS_SAFARI = IS_SAFARI;
	}
};
